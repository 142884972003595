import shared from '@principal/life-calculator-helpers';

export const FUND_TYPE_COSTS = shared.FUND_TYPE_COSTS;

// These are the global key names for the calculator state
export const CALC_OPTIONS_PAGE = 'calc:options';

export const CALC_UNSURE_PAGE = 'calc:unsure';

export const INFO_PAGES = shared.INFO_PAGES;
export const INFO_PAGES_KEY = shared.INFO_PAGES_KEY;
export const INFO_PAGES_COMPLETE = 'calc:info:complete';

export const REPLACE_INCOME_PAGE = shared.REPLACE_INCOME_PAGE;
export const REPLACE_INCOME_AMOUNT = shared.REPLACE_INCOME_AMOUNT;
export const REPLACE_INCOME_MULTIPLIER = shared.REPLACE_INCOME_MULTIPLIER;
export const REPLACE_INCOME_YEARS = shared.REPLACE_INCOME_YEARS;
export const REPLACE_INCOME_GROWTH_RATE = shared.REPLACE_INCOME_GROWTH_RATE;

export const COVER_DEBT_PAGE = shared.COVER_DEBT_PAGE;
export const COVER_MORTGAGE_KEY = shared.COVER_MORTGAGE_KEY;
export const COVER_STUDENT_LOAN_KEY = shared.COVER_STUDENT_LOAN_KEY;
export const COVER_OTHER_KEY = shared.COVER_OTHER_KEY;

export const FUND_EDUCATION_PAGE = shared.FUND_EDUCATION_PAGE;
export const FUND_NUMBER_KEY = shared.FUND_NUMBER_KEY;
export const FUND_TYPE_KEY = shared.FUND_TYPE_KEY;
export const FUND_TYPE_COST_KEY = shared.FUND_TYPE_COST_KEY;
export const FUND_TYPE_YEARS = shared.FUND_TYPE_YEARS;

export const FINAL_EXPENSES_BURIAL = shared.FINAL_EXPENSES_BURIAL;
export const FINAL_EXPENSES_FUNERAL = shared.FINAL_EXPENSES_FUNERAL;


// Calc Results Pie Chart
export const CALC_RESULTS_PAGE = 'calc:results';
export const CALC_RESULTS_PIE = 'calc:results:pie';


// Quote Keys
export const ABOUT_PAGE = 'quote:about';

export const ABOUT_DOB = shared.ABOUT_DOB;
export const ABOUT_GENDER = shared.ABOUT_GENDER;
export const ABOUT_HEIGHT = shared.ABOUT_HEIGHT;
export const ABOUT_STATE = shared.ABOUT_STATE;
export const ABOUT_WEIGHT = shared.ABOUT_WEIGHT;
export const ABOUT_TOBACCO = shared.ABOUT_TOBACCO;


export const QUOTE_PAGE = shared.QUOTE_PAGE;
export const QUOTE_RESULT_PAGE = shared.QUOTE_RESULT_PAGE;
export const QUOTE_FACE_VALUE = shared.QUOTE_FACE_VALUE;
export const QUOTE_TERM_RATES = shared.QUOTE_TERM_RATES;

// Contact Keys
export const CONTACT_NAME = shared.CONTACT_NAME;
export const CONTACT_EMAIL = shared.CONTACT_EMAIL;
export const CONTACT_PHONE = shared.CONTACT_PHONE;
export const CONTACT_ZIP_CODE = shared.CONTACT_ZIP_CODE;
export const CONTACT_APPOINTMENT = shared.CONTACT_APPOINTMENT;
export const CONTACT_HOUR_KEY = shared.CONTACT_HOUR_KEY;

export const CONTACT_HOUR_OPTIONS = {
    'between-8am-11am': {
        label: 'Between 8:00 AM - 11:00 AM CT',
        hour: 8
    },
    'between-11am-1pm': {
        label: 'Between 11:00 AM - 1:00 PM CT',
        hour: 11
    },
    'between-1pm-4pm': {
        label: 'Between 1:00 PM - 4:00 PM CT',
        hour: 13
    },
    'between-4pm-5pm': {
        label: 'Between 4:00 PM - 5:00 PM CT',
        hour: 16
    },
    'between-5pm-7pm': {
        label: 'Between 5:00 PM - 7:00 PM CT',
        hour: 17
    }
};

// CONTACT Helper Methods
export { default } from './convert-date-helper';

// STATES
export const ABOUT_STATE_OPTIONS = {
    AL: {
        label: 'Alabama'
    },
    AK: {
        label: 'Alaska'
    },
    AZ: {
        label: 'Arizona'
    },
    AR: {
        label: 'Arkansas'
    },
    CA: {
        label: 'California'
    },
    CO: {
        label: 'Colorado'
    },
    CT: {
        label: 'Connecticut'
    },
    DE: {
        label: 'Delaware'
    },
    DC: {
        label: 'District Of Columbia'
    },
    FL: {
        label: 'Florida'
    },
    GA: {
        label: 'Georgia'
    },
    HI: {
        label: 'Hawaii'
    },
    ID: {
        label: 'Idaho'
    },
    IL: {
        label: 'Illinois'
    },
    IN: {
        label: 'Indiana'
    },
    IA: {
        label: 'Iowa'
    },
    KS: {
        label: 'Kansas'
    },
    KY: {
        label: 'Kentucky'
    },
    LA: {
        label: 'Louisiana'
    },
    ME: {
        label: 'Maine'
    },
    MD: {
        label: 'Maryland'
    },
    MA: {
        label: 'Massachusetts'
    },
    MI: {
        label: 'Michigan'
    },
    MN: {
        label: 'Minnesota'
    },
    MS: {
        label: 'Mississippi'
    },
    MO: {
        label: 'Missouri'
    },
    MT: {
        label: 'Montana'
    },
    NE: {
        label: 'Nebraska'
    },
    NV: {
        label: 'Nevada'
    },
    NH: {
        label: 'New Hampshire'
    },
    NJ: {
        label: 'New Jersey'
    },
    NM: {
        label: 'New Mexico'
    },
    NY: {
        label: 'New York'
    },
    NC: {
        label: 'North Carolina'
    },
    ND: {
        label: 'North Dakota'
    },
    OH: {
        label: 'Ohio'
    },
    OK: {
        label: 'Oklahoma'
    },
    OR: {
        label: 'Oregon'
    },
    PA: {
        label: 'Pennsylvania'
    },
    RI: {
        label: 'Rhode Island'
    },
    SC: {
        label: 'South Carolina'
    },
    SD: {
        label: 'South Dakota'
    },
    TN: {
        label: 'Tennessee'
    },
    TX: {
        label: 'Texas'
    },
    UT: {
        label: 'Utah'
    },
    VT: {
        label: 'Vermont'
    },
    VA: {
        label: 'Virginia'
    },
    WA: {
        label: 'Washington'
    },
    WV: {
        label: 'West Virginia'
    },
    WI: {
        label: 'Wisconsin'
    },
    WY: {
        label: 'Wyoming'
    }
};

// Route Map
export const PAGE_MAP = {
    '/life-calculator': 'Calculator & Quote',
    '/life-calculator/calculator': 'Calculator',
    '/life-calculator/calculator/get-help': 'Get Help',
    '/life-calculator/calculator/info/replace-income': 'Replace Income',
    '/life-calculator/calculator/info/cover-debt': 'Cover Debt',
    '/life-calculator/calculator/info/fund-education': 'Fund Education',
    '/life-calculator/calculator/results': 'Calculator Results',
    '/life-calculator/quote/about': 'Quote About',
    '/life-calculator/quote/results': 'Quote Rates and Coverage'
};
