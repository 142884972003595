import React from 'react';
import PropTypes from 'prop-types';
import { webAnalytics as wa } from '@principal/web-analytics';
import ContactForm from '../contact-form/contact-form';

import styles from '../../css/calc-unsure-cta.module.css';

const ActionCard = ({ action, className }) => {
    const classes = [styles.actionCard];

    if (className) {
        classes.push(className);
    }

    return (
        <div className={classes.join(' ')}>
            <div className={styles.actionAction}>{action}</div>
        </div>
    );
};

ActionCard.propTypes = {
    action: PropTypes.node.isRequired,
    className: PropTypes.string
};

ActionCard.defaultProps = { className: null };

const CallToActionComponent = ({ compact, state, actions }) => {
    const classes = [styles.callsToAction];

    if (compact) {
        classes.push(styles.compact);
    }

    return (
        <div className={classes.join(' ')}>
            <ActionCard className={styles.advisor}
                action={
                    <div>
                        Contact your financial professional. If you don't have one,&nbsp;
                        <div className={styles.findFPLink}>
                            <a href="javascript:void(0)"
                                type="text"
                                data-gtm="call-to-action"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    actions.showModalContent(
                                        'modal',
                                        () => <span>Find a financial professional</span>,
                                        () => (
                                            <ContactForm.Component advisor
                                                state={state}
                                                actions={actions}
                                            />
                                        )
                                    );
                                    wa.trackEvent('modalView', {
                                        modaltitle: 'Find a financial professional'
                                    });
                                }}
                            >
              find a financial professional in your area
                            </a>
                        </div>
                    </div>
                }
            />
        </div>
    );
};

CallToActionComponent.propTypes = {
    compact: PropTypes.bool,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.shape().isRequired
};
CallToActionComponent.defaultProps = { compact: false };

export default { Component: CallToActionComponent };
