@value boldFont, largeScreen, principalBlue, pdsMediumLightGray, pdsMediumGray from './common.module.css';
@value smallScreen, largeScreen from './common.module.css';

/* Page Progress Styling */
.pageProgress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto 70px auto;
    width: 230px;
}
@media all and (min-width: smallScreen) {
    .pageProgress {
        width: 260px;
    }
}
@media all and (min-width: largeScreen) {
    .pageProgress {
        width: 280px;
    }
}


.meter {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
}

.meterSpacer {
    height: 0;
    flex-grow: 1;
    border: 2px solid;
}

.meterSpacer.enabled {
    border-color: principalBlue;
}

.meterSpacer.disabled {
    border-color: pdsMediumLightGray;
}

.meterText {
    position: absolute;
    top: 40px;
    width: 80px;
    text-align: center;
}

.meterText p {
    margin: 0;
    font-size: 17px;
    color: principalBlue;
}

.meterSegment {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.meterSegment.current .meterText p {
    font-family: boldFont;
    cursor: pointer;
}

.meterSegment.disabled .meterText p {
    color: pdsMediumGray;
}

.meterMarker {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
}

.meterMarker:focus {
    border: 3px solid orange;
    border-radius: 50%;
    outline: none;
}

.meterSegment.enabled .meterMarker {
    border-color: principalBlue;
    cursor: pointer;
}

.meterSegment.enabled .meterMarker:focus {
    border: 3px solid orange;
    border-radius: 50%;
    outline: none;
}

.meterSegment.current .meterMarker {
    background: principalBlue;
}

.meterSegment.disabled .meterMarker {
    border-color: pdsMediumLightGray;
}

.meterSegment.disabled .meterMarker:focus {
    border-color: orange;
    border-radius: 50%;
}
