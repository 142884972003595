import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';

import infoBlurbStyles from '../../css/info-blurb.module.css';
import styles from '../../css/calc-info-forms.module.css';

import CoverDebtIcon from '../../img/cover-debt-icon.png';
import FundEducationIcon from '../../img/fund-education-icon.png';
import ReplaceIncomeIcon from '../../img/replace-income-icon.png';

import DollarInput from '../dollar-input';
import Label from '../label';
import NextButton from '../next-button';
import NumberInput from '../number-input';
import PageProgress from '../page-progress';
import Slider from '../slider';

import calculator from '../../calculator';

import {
    CALC_OPTIONS_PAGE,
    INFO_PAGES_KEY,
    INFO_PAGES_COMPLETE,
    REPLACE_INCOME_PAGE,
    REPLACE_INCOME_AMOUNT,
    REPLACE_INCOME_MULTIPLIER,
    REPLACE_INCOME_YEARS,
    COVER_DEBT_PAGE,
    COVER_MORTGAGE_KEY,
    COVER_STUDENT_LOAN_KEY,
    COVER_OTHER_KEY,
    FUND_EDUCATION_PAGE,
    FUND_NUMBER_KEY,
    FUND_TYPE_KEY,
    FUND_TYPE_COST_KEY,
    FUND_TYPE_YEARS,
    FUND_TYPE_COSTS
} from '../../constants';


const CalcInfoFormState = ({
    Initial: () => ({
        [INFO_PAGES_KEY]: PageProgress.State.Initial('/life-calculator/calculator/info')
    }),
    Actions: (update) => (
        {
            viewCalcResults: () => update(
                state => {
                    if (state[REPLACE_INCOME_MULTIPLIER] === 12) {
                        const annualIncome = (
                            DollarInput.State.get(
                                state[REPLACE_INCOME_AMOUNT]
                            ) * state[REPLACE_INCOME_MULTIPLIER]
                        );

                        state[REPLACE_INCOME_AMOUNT] = DollarInput.State.set(
                            state[REPLACE_INCOME_AMOUNT],
                            annualIncome
                        );

                        state[REPLACE_INCOME_MULTIPLIER] = 1;
                    }

                    state[INFO_PAGES_COMPLETE] = true;

                    return calculator.updateCalcResultsSlices(state);
                }
            ),
            setIncomeMultiplier: input => update(
                { [REPLACE_INCOME_MULTIPLIER]: input === '12' ? 12 : 1 }
            ),
            setEducationCostScale: (typeKey) => update(
                state => {
                    state[FUND_TYPE_KEY] = typeKey;

                    const { average, max, increments, years } = (
                        FUND_TYPE_COSTS[typeKey]
                    );

                    state[FUND_TYPE_COST_KEY] = (
                        Slider.State.setPointer(
                            Slider.State.setPositionNearestValue(
                                Slider.State.setBounds(
                                    state[FUND_TYPE_COST_KEY],
                                    0,
                                    max,
                                    increments
                                ),
                                average
                            ),
                            { label: 'National average*', value: average }
                        )
                    );

                    state[FUND_TYPE_YEARS] = NumberInput.State.set(
                        state[FUND_TYPE_YEARS],
                        years
                    );

                    return state;
                }
            ),
            ...PageProgress.State.Actions(update),
            ...DollarInput.State.Actions(update),
            ...NumberInput.State.Actions(update),
            ...Slider.State.Actions(update)
        }
    )
});


const ReplaceIncome = ({ state, actions }) => (
    <main className={styles.replaceForm}>
        <div className={styles.icon}>
            <img src={ReplaceIncomeIcon} alt="" />
        </div>
        <h1 className={styles.sectionTitle}>
            Replace income
        </h1>
        <div className={styles.replaceRow}>
            <span>I&nbsp;make</span>
            <div className={styles.income}>
                <DollarInput.Component id={REPLACE_INCOME_AMOUNT}
                    state={state}
                    actions={actions}
                    hideErrors
                    ariaLabel="Income Amount"
                />
            </div>
            <span>per</span>
            <div className={styles.incomeMultiplier}>
                <label htmlFor={REPLACE_INCOME_MULTIPLIER} className={styles.srOnly}> Select an income frequency, year or month </label>
                <select id={REPLACE_INCOME_MULTIPLIER}
                    value={state[REPLACE_INCOME_MULTIPLIER]}
                    onChange={evt => (
                        actions.setIncomeMultiplier(evt.target.value)
                    )}
                    className={
                        state[REPLACE_INCOME_MULTIPLIER] === 1
                            ? styles.year
                            : styles.month
                    }
                >
                    <option value="1">year</option>
                    <option value="12">month</option>
                </select>
            </div>
            <span>.</span>
        </div>
        <div className={styles.replaceRow}>
            <span>I&nbsp;want&nbsp;to&nbsp;replace&nbsp;this&nbsp;income</span>
        </div>
        <div className={styles.replaceRow}>
            <span>for&nbsp;my&nbsp;family&nbsp;for</span>
            <div className={styles.replaceYears}>
                <NumberInput.Component className={styles.replaceYears}
                    id={REPLACE_INCOME_YEARS}
                    state={state}
                    actions={actions}
                    ariaLabel="Number of years of income to replace income"
                />
            </div>
            <span>years.</span>
        </div>
        <div className={styles.nextButton}>
            <NextButton.Component state={state} actions={actions} />
        </div>
        <div className={infoBlurbStyles.blurb}>
            <div className={infoBlurbStyles.title}>
                Why it matters
            </div>
            <div className={infoBlurbStyles.body}>
                <p>
                    Your family depends on your income. Term life insurance
                    can help them cover costs your salary helps pay, like groceries
                    and other everyday expenses.
                </p>
                <p>
                    Generally, you may want to consider providing enough coverage for your family
                    to manage five to 10 years of expenses. You’ll be able to adjust this later.
                </p>
            </div>
        </div>
    </main>
);

ReplaceIncome.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};


const CoverDebt = ({ state, actions }) => (
    <main className={styles.coverForm}>
        <div className={styles.icon}>
            <img src={CoverDebtIcon} alt="" />
        </div>
        <h1 className={styles.sectionTitle}>
            Cover debt
        </h1>
        <div className={styles.coverRow}>
            <DollarInput.Component id={COVER_MORTGAGE_KEY}
                label="Mortgage"
                tooltip="Total mortgage amount"
                state={state}
                actions={actions}
                hideErrors
            />
        </div>
        <div className={styles.coverRow}>
            <DollarInput.Component id={COVER_STUDENT_LOAN_KEY}
                label="Student loans"
                tooltip="Total student loan amount"
                state={state}
                actions={actions}
                hideErrors
            />
        </div>
        <div className={styles.coverRow}>
            <DollarInput.Component id={COVER_OTHER_KEY}
                label="Other debt"
                tooltip="Credit card, car loans, etc."
                state={state}
                actions={actions}
                hideErrors
            />
        </div>
        <div className={styles.nextButton}>
            <NextButton.Component state={state} actions={actions} />
        </div>
        <div className={infoBlurbStyles.blurb}>
            <div className={infoBlurbStyles.title}>
                Why it matters
            </div>
            <div className={infoBlurbStyles.body}>
                <p>
                    Unpaid debt could create a financial burden for your family after you’re gone.
                </p>
                <p>
                    In calculating your coverage needs, we differentiate between types of debt, such
                    as mortgage and managing student debt. These are handled differently than debts
                    like credit cards and other consumer debt.
                </p>
                <p>
                    Estimating what you owe is fine for the purpose of approximating coverage amounts.
                </p>
            </div>
        </div>
    </main>
);

CoverDebt.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};

const FundEducation = ({ state, actions }) => (
    <main className={styles.fundForm}>
        <div className={styles.icon}>
            <img src={FundEducationIcon} alt="" />
        </div>
        <h1 className={styles.sectionTitle}>
            Fund education
        </h1>
        <div className={styles.fundRow}>
            <label htmlFor={FUND_NUMBER_KEY}>
                Number of children or others attending
                <NumberInput.Component id={FUND_NUMBER_KEY}
                    state={state}
                    actions={actions}
                />
            </label>
        </div>
        <div className={styles.fundRow}>
            <label htmlFor={FUND_TYPE_KEY}>Type of school</label>
            <select id={FUND_TYPE_KEY}
                value={state[FUND_TYPE_KEY]}
                onChange={evt => (
                    actions.setEducationCostScale(evt.target.value)
                )}
            >
                {Object.entries(FUND_TYPE_COSTS).map(
                    ([key, { label }]) => (
                        <option key={`${FUND_TYPE_KEY}-${key}`}
                            value={key}
                        >
                            {label}
                        </option>
                    )
                )}
            </select>
        </div>
        <div className={styles.fundRow}>
            <Label.Component id={FUND_TYPE_COST_KEY}
                required={false}
                label="Cost per year"
                tooltip="Cost per year per attendee"
                htmlFor={FUND_TYPE_COST_KEY}
            />
            <Slider.Component id={FUND_TYPE_COST_KEY}
                state={state}
                actions={actions}
            />
        </div>
        <div className={styles.nextButton}>
            <NextButton.Component state={state} actions={actions} />
        </div>
        <div className={infoBlurbStyles.blurb}>
            <div className={infoBlurbStyles.title}>
                Why it matters
            </div>
            <div className={infoBlurbStyles.body}>
                <p>
                    If one of your financial goals is to help your child or children
                    pay for their education, term insurance can be one way to help you
                    achieve that goal, even after you’re gone. It can help protect what you’ve
                    saved and make money available to help cover their college expenses.
                </p>
            </div>
        </div>
    </main>
);

FundEducation.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};


const ShowForm = ({ state, actions }) => {
    const location = useLocation();

    const pageMap = {
        cover: () => (
            <CoverDebt id={COVER_DEBT_PAGE}
                state={state}
                actions={actions}
            />
        ),
        fund: () => (
            <FundEducation id={FUND_EDUCATION_PAGE}
                state={state}
                actions={actions}
            />
        ),
        replace: () => (
            <ReplaceIncome id={REPLACE_INCOME_PAGE}
                state={state}
                actions={actions}
            />
        )
    };

    return PageProgress.State.renderPage(
        state[INFO_PAGES_KEY], location, pageMap
    );
};

ShowForm.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};


const CalcInfoFormComponent = ({ state, actions }) => {
    if (Object.keys(state[CALC_OPTIONS_PAGE].choices).length <= 0) {
        return <Redirect to="/life-calculator/calculator" />;
    }

    return (
        <div className={styles.infoForms}>
            <PageProgress.Component id={INFO_PAGES_KEY}
                state={state}
                actions={actions}
            />
            <ShowForm state={state} actions={actions} />
        </div>
    );
};

CalcInfoFormComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};


export default {
    State: CalcInfoFormState,
    Component: CalcInfoFormComponent
};
