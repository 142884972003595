/* Date Entry Styling */
.input {
    display: flex;
    flex-direction: row;
}

::placeholder {
    color: #757575;
}

.input > div {
    margin-right: 20px;
}

.month,
.day {
    width: 60px;
}

.year {
    width: 100px;
}

/* general stuff */
.fieldDescription {
    composes: fieldDescription from './common.module.css';
}
