import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '@principal/life-calculator-helpers';

import stateChecker from '../../state-checker';

import styles from '../../css/slider.module.css';


const setBounds = (state, min, max, increments) => {
    state.min = min;
    state.max = max;
    state.increments = increments;

    if (state.position < 1) {
        state.position = 1;
    }

    if (state.increments < state.position) {
        state.position = state.increments;
    }

    return state;
};

const getBounds = ({ min, max, increments }) => ({ min, max, increments });

const setPointer = (state, pointer) => {
    if (pointer === null) {
        state.scaleType = 'scale';
        state.pointer = null;
        return state;
    }

    const { label, value } = pointer;
    state.pointer = { label, value };
    state.scaleType = 'pointer';
    return state;
};

const setCustomValueGetter = (state, valueGetter) => {
    state.valueGetter = valueGetter;

    return state;
};

const getLinearValue = ({ min, max, increments, position }) => (
    min + (((max - min) / (increments - 1)) * (position - 1))
);

const getValue = state => state.valueGetter(state);

const getPositionNearestValue = ({ min, max, increments }, value) => (
    Math.max(
        Math.min(
            Math.round((((value - min) * (increments - 1)) / (max - min)) + 1),
            increments
        ),
        1
    )
);

const setPosition = (state, position) => {
    state.position = Math.max(
        Math.min(
            position,
            state.increments
        ),
        1
    );

    return state;
};

const setPositionNearestValue = (state, value) => {
    const { min, max, increments } = state;

    state.position = Math.max(
        Math.min(
            Math.round((((value - min) * (increments - 1)) / (max - min)) + 1),
            increments
        ),
        1
    );

    return state;
};

const getPxPerIncrement = ({ domNode, increments }) => {
    const { left, width } = domNode.getBoundingClientRect();

    // We subtract the width of the bar ends here for accuracy.
    const pxPerIncrement = (width - 24) / (increments - 1);

    return { left, pxPerIncrement };
};

const getMarkerOffset = (pageX, { markerDomNode }) => {
    const { left } = markerDomNode.getBoundingClientRect();

    return pageX - left;
};

const decrementPos = (state, increment) => {
    state.position = Math.max(
        1,
        state.position - increment
    );

    return state;
};

const incrementPos = (state, increment) => {
    state.position = Math.min(
        state.increments,
        state.position + increment
    );

    return state;
};

const updatePos = (state, xPos) => {
    const { lastX, dragOffset, position, increments } = state;

    const { left, pxPerIncrement } = getPxPerIncrement(state);

    const movement = xPos - lastX;
    const rounder = movement > 0 ? Math.ceil : Math.floor;
    const incrementsMoved = rounder(
        pxPerIncrement > 1
            ? (movement / pxPerIncrement)
            : (increments / movement)
    );

    if (pxPerIncrement > 0 && Math.abs(movement) < (pxPerIncrement / 1.5)) {
        return state;
    }

    state.position = Math.max(
        Math.min(
            position + incrementsMoved,
            increments
        ),
        1
    );

    state.lastX = (
        // Left Edge + Marker position + cursor offset _within_ marker
        left
        + ((state.position - 1) * Math.max(pxPerIncrement, 1))
        + dragOffset
    );

    return state;
};


const SliderState = ({
    Initial: ({ min, max, increments, position, valueGetter, pointer }) => ({
        // User set params
        min: Math.min(min, max),
        max: Math.max(min, max),
        increments: Math.max(increments, 2),
        position: Math.min(
            Math.max(position, 1), // This is the min position.
            Math.max(increments, 2) // This is max increments.
        ),
        valueGetter: valueGetter || getLinearValue,
        scaleType: pointer !== undefined ? 'pointer' : 'scale',
        pointer: (
            pointer !== undefined
                ? { label: pointer.label, value: pointer.value }
                : null
        ),
        // Internal state
        dragging: false,
        domNode: null,
        markerDomNode: null,
        lastX: null
    }),
    getValue,
    setBounds,
    getBounds,
    setPointer,
    setPosition,
    setPositionNearestValue,
    setCustomValueGetter,
    Actions: update => ({
        Decrement: (id, increment) => {
            if (!Number.isFinite(increment)) {
                throw TypeError('increment must be a finite number');
            }

            return update({ [id]: state => decrementPos(state, increment) });
        },
        Increment: (id, increment) => {
            if (!Number.isFinite(increment)) {
                throw TypeError('increment must be a finite number');
            }

            return update({ [id]: state => incrementPos(state, increment) });
        },
        DragStart: (id, evt) => {
            const { pageX } = evt;

            return update({
                [id]: state => {
                    state.dragging = true;
                    state.dragOffset = getMarkerOffset(pageX, state);
                    state.lastX = pageX;

                    return state;
                }
            });
        },
        TouchStart: (id, evt) => {
            const { pageX } = evt.targetTouches[0];

            return update({
                [id]: {
                    dragging: true,
                    lastX: pageX,
                    dragOffset: 0
                }
            });
        },
        Drag: (id, dragging, evt) => {
            if (!dragging) {
                return;
            }

            const { pageX, buttons } = evt;

            // Check if mouse-buttons are up and we need to Drop. We can get
            // stuck in drag-mode if someone rapidly exists the Slider then
            // comes back in. But, if they've still got the mouse button down,
            // we want to keep drag'n.
            if (buttons === 0) {
                return update({ [id]: { dragging: false } });
            }

            if (pageX === 0) {
                // When the drag ends, some browsers (eh hm Chrome)  sometimes
                // fire a final event with pageX set to 0. Ignore that event.
                return;
            }

            return update({ [id]: state => updatePos(state, pageX) });
        },
        TouchMove: (id, evt) => {
            const pageX = evt.targetTouches[0].pageX;

            return update({ [id]: state => updatePos(state, pageX) });
        },
        Drop: id => update({ [id]: { dragging: false } }),
        SetSliderControlDOMNode: (id, domNode) => update({
            [id]: state => {
                // This guard is required to prevent callback loops.
                if (state.domNode !== domNode) {
                    state.domNode = domNode;
                }
                return state;
            }
        }),
        SetSliderMarkerDOMNode: (id, domNode) => update({
            [id]: state => {
                // This guard is required to prevent callback loops.
                if (state.markerDomNode !== domNode) {
                    state.markerDomNode = domNode;
                }
                return state;
            }
        })
    })
});


const SliderLabel = ({ increments, position, value }) => (
    <div className={styles.label}>
        <div className={styles.labelAligner} style={{ flex: position - 1 }} />
        <div className={styles.labelText}>
            $
            {value}
        </div>
        <div className={styles.labelAligner}
            style={{ flex: increments - position }}
        />
    </div>
);

SliderLabel.propTypes = {
    increments: PropTypes.number.isRequired,
    position: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired
};


const SliderControl = ({ id, state, actions }) => {
    const { increments, position } = state[id];

    const getSliderControlRef = useCallback(
        node => actions.SetSliderControlDOMNode(id, node), []
    );
    const getSliderMarkerRef = useCallback(
        node => actions.SetSliderMarkerDOMNode(id, node), []
    );

    return (
        <div className={styles.control} ref={getSliderControlRef}>
            <div role="button"
                tabIndex="-1"
                className={`${styles.bar} ${styles.left}`}
                aria-label="decrease coverage amount"
                onKeyDown={
                    evt => {
                        if (evt.keyCode === 13) {
                            actions.Decrement(id, 1);
                        }
                    }
                }
                style={{ flex: position - 1 }}
                onClick={() => actions.Decrement(id, 1)}
            >
                <div className={styles.barMarker} />
                <div className={styles.barScale} />
            </div>
            <div role="button"
                tabIndex="0"
                aria-label="slider marker"
                onKeyDown={
                    evt => {
                        if (evt.keyCode === 37) {
                            actions.Decrement(id, 1);
                        } else if (evt.keyCode === 39) {
                            actions.Increment(id, 1);
                        }
                    }
                }
                className={styles.marker}
                onMouseDown={(evt) => actions.DragStart(id, evt)}
                onMouseUp={(evt) => actions.Drop(id, evt)}

                onTouchStart={(evt) => actions.TouchStart(id, evt)}
                onTouchMove={(evt) => actions.TouchMove(id, evt)}
                onTouchEnd={() => actions.Drop(id)}

                ref={getSliderMarkerRef}
            />
            <div role="button"
                tabIndex="0"
                aria-label="increase coverage amount"
                onKeyDown={
                    evt => {
                        if (evt.keyCode === 13) {
                            actions.Increment(id, 1);
                        }
                    }
                }
                className={styles.bar}
                style={{ flex: increments - position }}
                onClick={() => actions.Increment(id, 1)}
            >
                <div className={styles.barScale} />
                <div className={styles.barMarker} />
            </div>
        </div>
    );
};

SliderControl.propTypes = {
    id: PropTypes.string.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        PropTypes.shape({
            position: PropTypes.number.isRequired,
            increments: PropTypes.number.isRequired
        }).isRequired
    ),
    actions: PropTypes.shape({
        Increment: PropTypes.func.isRequired,
        Decrement: PropTypes.func.isRequired,
        DragStart: PropTypes.func.isRequired,
        Drop: PropTypes.func.isRequired,
        TouchMove: PropTypes.func.isRequired,
        TouchStart: PropTypes.func.isRequired,
        SetSliderControlDOMNode: PropTypes.func.isRequired,
        SetSliderMarkerDOMNode: PropTypes.func.isRequired
    }).isRequired
};


const SliderScale = ({ min, max }) => (
    <div className={styles.scaleLabel}>
        <div className={styles.labelLeft}>
            <span className={styles.scaleText}>{formatNumber(min)}</span>
        </div>
        <div className={styles.labelRight}>
            <span className={styles.scaleText}>{formatNumber(max)}</span>
        </div>
    </div>
);

SliderScale.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired
};


const SliderPointer = ({ min, max, increments, value, label }) => {
    const position = getPositionNearestValue({ min, max, increments }, value);

    return (
        <div className={styles.pointer}>
            <div className={styles.pointerAligner}
                style={{ flex: position - 1 }}
            />
            <div className={styles.pointerLines} />
            <div className={styles.pointerLabel}
                style={{ flex: increments - position }}
            >
                {label}
            </div>
        </div>
    );
};

SliderPointer.propTypes = {
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    increments: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
};


const SliderComponent = ({ state, id, actions }) => {
    const {
        min,
        max,
        increments,
        position,
        dragging,
        valueGetter,
        scaleType
    } = state[id];

    const value = formatNumber(valueGetter(state[id]));

    const scale = (scaleType === 'pointer' && state[id].pointer
        ? <SliderPointer min={min}
            max={max}
            increments={increments}
            value={state[id].pointer.value}
            label={state[id].pointer.label}
        />
        : <SliderScale min={min} max={max} />
    );

    return (
        <div className={styles.slider}
            onMouseDown={evt => evt.preventDefault()}
            onMouseEnter={evt => actions.Drag(id, dragging, evt)}
            onMouseMove={evt => actions.Drag(id, dragging, evt)}
            onMouseUp={evt => actions.Drop(id, evt)}
            role="slider"
            aria-label="Select your coverage amount"
            aria-valuenow={parseInt(value)}
            aria-valuemin={200000}
            aria-valuemax={5000000}
            tabIndex={0}
        >
            <SliderLabel increments={increments}
                position={position}
                value={value}
            />
            <SliderControl id={id} state={state} actions={actions} />
            {scale}
        </div>
    );
};

const actionPropTypes = PropTypes.shape({
    Increment: PropTypes.func.isRequired,
    Decrement: PropTypes.func.isRequired,
    Drag: PropTypes.func.isRequired,
    DragStart: PropTypes.func.isRequired,
    Drop: PropTypes.func.isRequired,
    TouchMove: PropTypes.func.isRequired,
    TouchStart: PropTypes.func.isRequired,
    SetSliderControlDOMNode: PropTypes.func.isRequired,
    SetSliderMarkerDOMNode: PropTypes.func.isRequired
});

const statePropTypes = PropTypes.shape({
    position: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    increments: PropTypes.number.isRequired,
    dragging: PropTypes.bool.isRequired,
    scaleType: PropTypes.string.isRequired,
    pointer: PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    })
});

SliderComponent.propTypes = {
    id: PropTypes.string.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        statePropTypes.isRequired
    ),
    actions: actionPropTypes.isRequired
};


export default {
    State: SliderState,
    Component: SliderComponent,
    statePropTypes,
    actionPropTypes
};
