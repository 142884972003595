import React from 'react';
import PropTypes from 'prop-types';


const ErrorMessage = ({ hasError, errorMsg }) => (hasError ? <span className="pds-error">{errorMsg}</span> : null);

ErrorMessage.defaultProps = {
    hasError: false,
    errorMsg: null
};

ErrorMessage.propTypes = {
    hasError: PropTypes.bool,
    errorMsg: PropTypes.string
};

export default ErrorMessage;
