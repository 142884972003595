import React from 'react';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';

import ErrorMessage from '../error-message';

import styles from '../../css/tobacco.module.css';


const validate = (state) => {
    if (state.tobacco === false) {
        state.isValid = true;
        state.hasError = false;
        state.errorMsg = null;
        return state;
    }

    if (state.tobacco === true) {
        if (state.tobaccoYears === 1
            || state.tobaccoYears === 2
            || state.tobaccoYears === 5) {
            state.isValid = true;
            state.hasError = false;
            state.errorMsg = null;
            return state;
        }

        state.isValid = false;
        state.hasError = true;
        state.errorMsg = 'Select years since last tobacco usage.';
        return state;
    }

    state.isValid = false;
    state.hasError = true;
    state.errorMsg = 'Invalid value for tobacco usage.';

    return state;
};

const set = (state, tobacco, tobaccoYears) => {
    state.tobacco = tobacco === true;
    state.tobaccoYears = (state.tobacco) ? tobaccoYears : null;

    return validate(state);
};

const TobaccoSelectState = {
    Initial: () => ({
        tobacco: false,
        tobaccoYears: null,
        isValid: true,
        hasError: false,
        errorMsg: null
    }),
    getTobacco: ({ tobacco }) => (
        tobacco
    ),
    getTobaccoYears: ({ tobaccoYears }) => (
        tobaccoYears
    ),
    set,
    isValid: state => state.isValid,
    Actions: update => ({
        validateTobacco: id => update({ [id]: state => validate(state) }),
        toggleTobacco: id => {
            update({
                [id]: state => {
                    state.tobacco = !state.tobacco;

                    if (!state.tobacco) {
                        state.tobaccoYears = null;
                        return validate(state);
                    }

                    state.isValid = false;
                    return state;
                }
            });
        },
        setTobaccoYears: (id, years) => {
            update({
                [id]: state => {
                    state.tobaccoYears = years;
                    return validate(state);
                }
            });
        }
    })
};

const TobaccoOption = ({ state, id, actions, value, label }) => (
    <div className={`${styles.yearsOption} pds-radio`}>
        <input type="radio"
            name={`${id}-years`}
            value={value}
            id={`radio-${id}-${value}`}
            checked={state.tobaccoYears === value}
            onChange={() => actions.setTobaccoYears(id, value)}
            onBlur={() => actions.validateTobacco(id)}
        />
        <label htmlFor={`radio-${id}-${value}`}>{label}</label>
    </div>
);

TobaccoOption.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        setTobaccoYears: PropTypes.func.isRequired,
        validateTobacco: PropTypes.func.isRequired
    }).isRequired,
    state: PropTypes.shape(
        { tobaccoYears: PropTypes.number }
    ).isRequired
};


const TobaccoUserOptions = ({ state, id, actions }) => {
    if (!state.tobacco) {
        return null;
    }

    return (
        <div className={styles.years}>
            <label className={styles.yearsLabel} htmlFor={`${id}-years`}>
                When was the last time you used tobacco or nicotine products in the past five years?
            </label>
            <TobaccoOption state={state}
                id={id}
                actions={actions}
                value={1}
                label="Within the last year"
            />
            <TobaccoOption state={state}
                id={id}
                actions={actions}
                value={2}
                label="Within the last 2 years"
            />
            <TobaccoOption state={state}
                id={id}
                actions={actions}
                value={5}
                label="Within the last 5 years"
            />
        </div>
    );
};

TobaccoUserOptions.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        setTobaccoYears: PropTypes.func.isRequired,
        validateTobacco: PropTypes.func.isRequired
    }).isRequired,
    state: PropTypes.shape({ tobacco: PropTypes.bool.isRequired }).isRequired
};


const TobaccoSelectComponent = ({ state, id, actions }) => (
    <div className={styles.tobaccoSelect}>
        <label className={styles.tobaccoUserLabel} htmlFor={id}>
            Have you used nicotine or tobacco products in the past five years?
        </label>
        <div className={styles.tobaccoUser}>
            <div className="pds-toggle">
                <input type="radio"
                    name={id}
                    id={`${id}-yes`}
                    checked={state[id].tobacco}
                    onChange={() => actions.toggleTobacco(id)}
                />
                <label htmlFor={`${id}-yes`}>Yes</label>
                <input type="radio"
                    name={id}
                    id={`${id}-no`}
                    checked={!(state[id].tobacco)}
                    onChange={() => actions.toggleTobacco(id)}
                    onBlur={() => actions.validateTobacco(id)}
                />
                <label htmlFor={`${id}-no`}>No</label>
            </div>
        </div>
        <TobaccoUserOptions state={state[id]} id={id} actions={actions} />
        <ErrorMessage hasError={state[id].hasError}
            errorMsg={state[id].errorMsg}
        />
    </div>
);


TobaccoSelectComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        toggleTobacco: PropTypes.func.isRequired,
        validateTobacco: PropTypes.func.isRequired
    }).isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        PropTypes.shape({
            tobacco: PropTypes.bool,
            errorMsg: PropTypes.string,
            hasError: PropTypes.bool.isRequired
        }).isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: TobaccoSelectState,
    Component: TobaccoSelectComponent
};
