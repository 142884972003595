import React from 'react';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';

import styles from '../../css/dollar-input.module.css';

import Label from '../label';
import NumberInput from '../number-input';
import ErrorMessage from '../error-message';


const DollarInputState = {
    Initial: (id, options) => NumberInput.State.Initial(id, {
        allowThousandsSep: true,
        ...options
    }),
    get: NumberInput.State.get,
    set: NumberInput.State.set,
    isValid: NumberInput.State.isValid,
    Actions: NumberInput.State.Actions
};


const DollarInputComponent = (
    { id, required, label, ariaLabel, tooltip, state, actions, hideErrors }
) => {
    const classNames = [styles.input];
    if (NumberInput.State.isEmpty(state[id])) {
        classNames.push(styles.empty);
    }

    const errors = (
        hideErrors
            ? null
            : <ErrorMessage hasError={state[id].hasError}
                errorMsg={state[id].errorMsg}
            />
    );

    return (
        <div className={styles.entry}>
            <Label.Component htmlFor={`number-${id}`}
                required={required}
                label={label}
                tooltip={tooltip}
            />
            <div className={classNames.join(' ')}>
                <span>$</span>
                <NumberInput.Component id={id}
                    state={state}
                    actions={actions}
                    ariaLabel={ariaLabel}
                />
            </div>
            {errors}
        </div>
    );
};

DollarInputComponent.defaultProps = {
    required: false,
    label: '',
    ariaLabel: '',
    tooltip: '',
    hideErrors: false
};

DollarInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    hideErrors: PropTypes.bool,
    label: PropTypes.string,
    ariaLabel: PropTypes.string,
    tooltip: PropTypes.string,
    actions: NumberInput.actionPropTypes.isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        NumberInput.statePropTypes.isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: DollarInputState,
    Component: DollarInputComponent,
    statePropTypes: NumberInput.statePropTypes,
    actionPropTypes: NumberInput.actionPropTypes
};
