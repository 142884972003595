import React, { useState } from 'react';
import PropTypes from 'prop-types';
import copy from 'copy-to-clipboard';
import { webAnalytics as wa } from '@principal/web-analytics';

import styles from '../../css/quote-result.module.css';

const ShareOrSave = ({ state, actions }) => {
    const [copyLinkText, setCopyLinkText] = useState('Copy Link');
    const [pdfText, setPdfText] = useState('Get PDF');

    return (
        <div className={styles.shareOrSave}>
            <div>
                <h2 className={styles.shareOrSaveTitle}>Share or save</h2>
                <div className={styles.shareOrSaveButtons}>
                    <button type="button"
                        name="getPDF"
                        id="viewPDF"
                        className={`pds-button pds-button-ghost ${styles.pdfButton}`}
                        data-gtm="call-to-action"
                        onClick={() => {
                            setPdfText('Getting PDF...');
                            actions.downloadQuotePdf(state)
                                .then(() => setPdfText('Get PDF'));
                            wa.trackEvent('formSubmit', {
                                type: 'contact-prospect',
                                name: 'getPDF',
                                id: 'viewPDF',
                                lead: 'no'
                            });
                        }}
                    >
                        {pdfText}
                    </button>
                    <button type="button"
                        name="copy-link"
                        id="copyLink1"
                        className={`pds-button pds-button-ghost ${styles.linkButton}`}
                        onClick={() => {
                            setCopyLinkText('Getting link...');
                            actions.getSharableQuoteURL(state)
                                .then(url => {
                                    copy(url);
                                    setCopyLinkText('Link Copied');
                                });
                            wa.trackEvent('modalClick', {
                                clicktext: 'Getting Link...',
                                modaltitle: 'Copy Link'
                            });
                        }}
                    >
                        {copyLinkText}
                    </button>
                </div>
            </div>
        </div>
    );
};

ShareOrSave.propTypes = {
    actions: PropTypes.shape({
        downloadQuotePdf: PropTypes.func.isRequired,
        getSharableQuoteURL: PropTypes.func.isRequired
    }).isRequired,
    state: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types, max-len
};

export default ShareOrSave;
