import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '@principal/life-calculator-helpers';

import stateChecker from '../../state-checker';

import styles from '../../css/pie-chart.module.css';


const addSlice = (state, { name, color, value }) => {
    state.totalValue += value;
    state.slices.push({ name, color, value });

    return state;
};

const addSlices = (state, slices) => (
    slices.reduce(
        (newState, slice) => addSlice(newState, slice),
        state
    )
);

const setSlices = (state, slices) => {
    state.slices = [];
    state.totalValue = 0;

    return addSlices(state, slices);
};

const hasSlices = ({ slices }) => (slices.length > 0);


const PieChartState = ({
    Initial: () => ({
        slices: [],
        totalValue: 0
    }),
    addSlice,
    addSlices,
    setSlices,
    hasSlices,
    getTotal: ({ totalValue }) => totalValue,
    Actions: (update) => ({
        addSlice: (id, slice) => (
            update({ [id]: { slices: (current) => current.push(slice) } })
        )
    })
});


const Slice = ({ pos, portion, slice }) => {
    let filler = null;
    const holderClassNames = [styles.sliceHold];

    if (portion > 180) {
        holderClassNames.push(styles.large);
        filler = (
            <div className={`${styles.slice} ${styles.filler}`}
                style={{ background: slice.color }}
            />
        );
    }

    return (
        <div className={holderClassNames.join(' ')}
            style={{ transform: `rotate(${pos}deg)` }}
        >
            <div className={styles.slice}
                style={{
                    transform: `rotate(${portion}deg)`,
                    background: slice.color
                }}
            />
            {filler}
        </div>
    );
};

Slice.propTypes = {
    pos: PropTypes.number.isRequired,
    portion: PropTypes.number.isRequired,
    slice: PropTypes.shape(
        { color: PropTypes.string.isRequired }
    ).isRequired
};


const Center = ({ content }) => (
    <div className={styles.centerDisc}>
        <div className={styles.centerContent}>
            <div className="pds-typography-bold pds-typography-h1">
                {content}
            </div>
        </div>
    </div>
);

Center.propTypes = { content: PropTypes.string.isRequired };


const PieChartComponent = ({ id, state }) => {
    const { slices, totalValue } = state[id];

    const { wedges } = slices.reduce((acc, slice) => {
        const portion = Math.round((slice.value / totalValue) * 360);

        acc.wedges.push(
            <Slice key={`${id}${slice.name}`}
                pos={acc.pos}
                portion={portion}
                slice={slice}
            />
        );
        acc.pos += portion;

        return acc;
    }, { pos: 0, wedges: [] });

    let formattedTotal = '';
    if (totalValue < 1000000) {
        formattedTotal = formatNumber(totalValue);
    } else {
        const scaledDollars = Math.round(totalValue / 10000) / 100;
        formattedTotal = `${formatNumber(scaledDollars)}m`;
    }


    return (
        <div className={styles.pieChart}>
            { wedges }
            <Center content={`$${formattedTotal}`} />
        </div>
    );
};

PieChartComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.shape(
        { addSlice: PropTypes.func.isRequired }
    ).isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        PropTypes.shape({
            slices: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    color: PropTypes.string.isRequired,
                    value: PropTypes.number.isRequired
                })
            ).isRequired,
            totalValue: PropTypes.number.isRequired
        }).isRequired
    )
};


const PieKeyComponent = ({ id, state, formatter }) => (
    <div className={styles.key}>
        {
            state[id].slices.map(slice => (
                <div className={styles.keyItem}
                    key={`${id}${slice.name}`}
                >
                    <div className={styles.keySwatch}
                        style={{ background: slice.color }}
                    />
                    <div className={styles.keyLabel}>
                        <div className={styles.keyTitle}>
                            {slice.name}
                        </div>
                        <div className={styles.keyAmount}>
                            {formatter(slice.value)}
                        </div>
                    </div>
                </div>
            ))
        }
    </div>
);

const statePropTypes = PropTypes.shape({
    slices: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            color: PropTypes.string.isRequired,
            value: PropTypes.number.isRequired
        })
    ).isRequired,
    totalValue: PropTypes.number.isRequired
});

PieKeyComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.shape(
        { addSlice: PropTypes.func.isRequired }
    ).isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        statePropTypes.isRequired
    ),
    formatter: PropTypes.func.isRequired
};


export default {
    State: PieChartState,
    ChartComponent: PieChartComponent,
    KeyComponent: PieKeyComponent,
    statePropTypes
};
