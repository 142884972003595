/* Primary Colors */
@value principalBlue: #0091DA;
@value principalCyan: #00C4D9;
@value principalMidnight: #464E7E;

/* Supporting Colors */
@value pdsLightBlue: #E4EFF9;
@value pdsLightCyan: #EAF6F7;
@value pdsLightMidnight: #E1E6F4;
@value pdsDarkBlue: #0076CF;
@value pdsDarkCyan: #00AECB;
@value pdsDarkMidnight: #162B48;

/* Typography colors */
@value pdsBlack: #000000;
@value pdsDarkGray: #464646;
@value pdsMediumDarkGray: #767676;
@value pdsMediumGray: #C7C7C7;
@value pdsMediumLightGray: #E6E6E6;
@value pdsLightGray: #F6F6F6;
@value pdsWhite: #FFFFFF;
@value pdsSuccess: #00A29B;
@value pdsWarning: #FF9231;
@value pdsError: #B30000;

/* General helpers */
@value boldFont: "FS Elliot Web Bold", "Arial", arial, sans-serif !important;
@value regularFont: "FS Elliot Web Regular", "Arial", arial, sans-serif;


/* Screen sizes (for media queries) */

@value tinyScreen: 375px;
@value smallScreen: 415px;
@value largeScreen: 768px;
@value bigScreen: 1134px;
@value hugeScreen: 1500px;


/* General */
html, body {
    display: flex;
    flex-direction: column;

    min-height: 100%;
    width: 100%;
}

body {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

/* General Section Headers */
.sectionTitle {
    font-size: 24px;
    font-family: boldFont;
    color: pdsDarkGray;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}

.sectionSubtitle {
    font-size: 24px;
    color: pdsDarkGray;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
}


/* buttons */
.nextButton  {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


/* General forms */
.label {
    color: pdsDarkGray;
}

.isRequired {
    margin-left: 5px;
    color: pdsError;
    font-family: boldFont;
}

.fieldDescription {
    color: pdsMediumDarkGray;
    font-size: 14px;
    font-family: regularFont;
}


/* Generic responsive stuff */
@media all and (min-width: hugeScreen) {
    .sectionTitle {
        font-size: 32px;
    }
}
