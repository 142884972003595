import React from 'react';
import PropTypes from 'prop-types';

import CallToAction from '../calc-unsure-cta';
import styles from '../../css/calc-unsure.module.css';


const CalcUnsureState = ({
    Initial: () => ({}),
    Actions: () => ({})
});

const CalcUnsureComponent = ({ state, actions }) => (
    <main className={styles.container}>
        <h1 className={styles.sectionTitle}>Life Insurance Calculator</h1>
        <div className={styles.getHelpText}>
            <div>
                Not sure what to cover? Want to talk through your options with
                a financial professional?
            </div>
            <div>We’re here to help.</div>
        </div>
        <CallToAction.Component state={state} actions={actions} />
    </main>
);

CalcUnsureComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types, max-len
};

export default {
    State: CalcUnsureState,
    Component: CalcUnsureComponent
};
