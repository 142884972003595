import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../css/label.module.css';

import Tooltip from '../tooltip';


const LabelState = {
    Initial: () => ({}),
    Actions: () => ({})
};


const Tip = ({ tip }) => (
    tip === null || tip.length === 0
        ? null
        : <Tooltip.Component tip={tip} />
);

Tip.defaultProps = { tip: '' };

Tip.propTypes = { tip: PropTypes.string };


const LabelComponent = ({ htmlFor, required, label, tooltip }) => {
    if (label === null || label.length === 0) return null;

    const classNames = [styles.label];

    return (
        <div className={classNames.join(' ')}>
            <label htmlFor={htmlFor}>{label}</label>
            {required ? <span className={styles.isRequired} aria-hidden="true"> * </span> : null}
            <Tip tip={tooltip} />
        </div>
    );
};

LabelComponent.defaultProps = {
    tooltip: '',
    required: false
};

LabelComponent.propTypes = {
    htmlFor: PropTypes.string.isRequired,
    required: PropTypes.bool,
    label: PropTypes.string.isRequired,
    tooltip: PropTypes.string
};

export default {
    State: LabelState,
    Component: LabelComponent
};
