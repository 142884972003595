@value regularFont, pdsDarkGray, pdsMediumGray, pdsMediumLightGray from './common.module.css';

.isRequired {
    composes: isRequired from './common.module.css';
}

/* Dollar Input Formatting */

.entry {
    display: flex;
    flex-direction: column;
}

.input {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    color: pdsDarkGray;
    font-family: regularFont;
    font-size: 1em;
    box-shadow: none;
    width: 100%;
    height: 33px !important;
    margin-left: -1px;
    -webkit-appearance: none;
}

.input > span {
    margin: 1px 5px 0 5px;
}

.input.empty > span {
    color: pdsMediumGray;
}

/* line input up with dollar sign  */
.input > input {
    padding-top: 11px;
    margin-bottom: 9px;
}

.input > input:focus, input:active, input:visited {
    outline: 5px auto -webkit-focus-ring;
    border: none;
}
