@value pdsDarkGray, pdsMediumLightGray, principalBlue, smallScreen, tinyScreen, hugeScreen from './common.module.css';

/* Calc Info Collection Forms */
.infoForms {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
}

.nextButton {
    composes: nextButton from './common.module.css';
}

.sectionTitle {
    composes: sectionTitle from './common.module.css';
}

.icon {
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.icon > img {
    width: 100px;
}

.srOnly {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
}

@media all and (min-width: smallScreen) {
    .infoForms {
        width: 390px;
    }
}

/* Calc Replace Income Form */
.replaceForm {
    display: flex;
    flex-direction: column;
}

.replaceRow {
    margin: 3px 0 3px 0;

    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;

    height: 33px;
}

.replaceRow > span {
    font-size: 20px;
    color: pdsDarkGray;
}

.replaceRow input {
    text-align: right;
    color: principalBlue;
    font-size: 20px;
    min-width: 10px;
    border: 0;
    border-bottom: 1px dashed principalBlue;
}

.replaceRow input:hover {
    border-bottom: 1px dashed principalBlue;
}

/* Income Amount */
.income {
    margin: 0 10px;
    width: 115px;
    border-bottom: 1px dashed principalBlue;
}

.income > div > div {
    border: 0;
    height: calc(100% - 2px);
    font-size: 20px;
    color: principalBlue;
}

/* override default PDS input hover styling */
.income > div > div > input:hover {
    border: 0 !important;
    border-bottom: 1px dashed principalBlue !important;
}

/* Income frequency */
.replaceRow select {
    margin: 0;
    padding: 0;
    border: 0;
    color: principalBlue;
    font-size: 20px;
    height: calc(100% - 2px);
    width: 100%;
}

.incomeMultiplier {
    height: 100%;
    width: 105px;
    margin-left: 10px;
    border-bottom: 1px dashed principalBlue;
}

.incomeMultiplier select.year {
    padding-left: 20px;
}


/* Replacement term */
.replaceYears {
    height: 100%;
    margin: 0 10px;
    border: none;
}

.replaceYears > input {
    width: 40px;
    height: calc(100% - 2px);
    margin: 0;
}

.replaceYears > input:hover {
    border: 0;
    border-bottom: 1px dashed principalBlue !important;
}

.replaceRow input:focus {
    outline: 5px auto -webkit-focus-ring-color;
    border-bottom: 1px dashed principalBlue;
}

@media all and (max-width: tinyScreen) {
    .replaceRow > span {
        font-size: 16px;
    }

    .replaceRow input {
        font-size: 16px;
    }

    .income > div > div {
        font-size: 16px;
    }

    .incomeMultiplier select {
        padding-top: 4px;
    }

    .replaceRow select {
        font-size: 16px;
    }

    .replaceYears > input {
        padding-top: 4px;
    }
}

@media all and (max-width: smallScreen) {
    .replaceRow select.year {
        padding-left: 20px;
    }

    .income {
        margin: 0 5px;
        width: 110px;
    }

    .incomeMultiplier {
        margin-left: 5px;
    }

    .replaceYears {
        margin: 0 5px;
    }
}

@media all and (min-width: hugeScreen) {
    .replaceRow {
        height: 40px;
    }

    .replaceRow > span,
    .replaceRow input,
    .replaceRow select,
    .income > div > div {
        font-size: 24px;
    }

    .income {
        width: 135px;
    }

    .replaceRow select.year {
        padding-left: 20px;
    }

    .incomeMultiplier {
        width: 120px;
    }
}


/* Calc Info - Cover Debt Form */
.coverForm {
    display: flex;
    flex-direction: column;
}

.coverRow {
    width: 100%;
    margin: 3px 0 20px  0;
    padding: 0 10px;

    display: flex;
    flex-direction: row;
}

.coverRow > div {
    flex: 1;
}

/* Calc Info - Fund Education Form */
.fundForm {
    display: flex;
    flex-direction: column;
}

.fundRow {
    width: 100%;
    margin: 3px 0 20px 0;
    padding: 0 10px;

    display: flex;
    flex-direction: column;
}

.fundRow > div {
    flex: 1;
}

.fundRow > input:hover {
    border: 1px solid pdsMediumLightGray !important;
}

/* Calc Info - Label */
.label {
    display: flex;
    flex-direction: row;
}
