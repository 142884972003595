import React from 'react';
import styles from '../../css/disclosure.module.css';

const Disclosure = () => {
    return (
        <>
            <div className={styles.disclosureContainer}>
                <p>
            This calculator is for illustrative purposes only. The results are
            based on your input, and are not intended to be a financial plan or
            advice from the Principal Financial Group®. This is not a life
            insurance policy or a complete statement of its provisions. The
            quote is an estimate only. Actual premium and rate class will vary
            based on a variety of factors. Eligibility, premiums, and rate class
            will be determined in the underwriting process. Your policy will
            provide costs and coverage details.
                    <br />
                    <br />
            Guarantees are based on the
            claims-paying ability of the issuing insurance company. All
            guarantees and benefits of the insurance policy are backed by the
            claims-paying ability of the issuing insurance company. Policy
            guarantees and benefits are not obligations of, nor backed by, the
            broker/dealer and/or insurance agency selling the policy, nor by any
            of their affiliates, and none of them makes any representations or
            guarantees regarding the claims-paying ability of the issuing
            insurance company.
                    <br />
                    <br />
            In exchange for the death benefit, life insurance
            products charge fees such as mortality and expense risk charges and
            surrender fees.
                    <br />
                    <br />
            Life insurance products issued by Principal National Life Insurance
            Company (except in NY) and Principal Life Insurance Company® , and
            the companies available through the Preferred Product Network, Inc.
            Plan administrative services provided by Principal Life. Referenced
            companies are members of the Principal Financial Group®, Des Moines,
            IA 50392.
                    <br />
                    <br />
            Principal National Life
            Insurance Company Policy Form: ICC23 SN 174 N /
            ICC23 SN 174 C / SN 174 N / SN 174 C
                    <br />
                    <br />
            Principal®, Principal Financial Group®, and Principal and the logomark design are registered
            trademarks of Principal Financial Services, Inc., a Principal Financial Group company, in the
            United States and are trademarks and services marks of Principal Financial Services, Inc., in
            various countries around the world.
                    <br />
                    <br />
            BB12420-01 | 09/2023 | 3081610-092023 | (C) 2023 Principal Financial Services, Inc.
                </p>
            </div>
            <p>3081610-092023</p>
        </>
    );
};
export default Disclosure;
