@value pdsDarkGray, regularFont, smallScreen from './common.module.css';

/* Navigation Bread Crumbs */
.breadcrumbs {
    font-size: 14px;
    line-height: 17px;
    color: pdsDarkGray;
    letter-spacing: 0;
    width: 100%;
}

.breadcrumbs ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    white-space: nowrap;
}

.breadcrumbs ul li {
    margin: 0px 3px 0px 0px;
    display: flex;
    flex-direction: row;
}

.breadcrumbs span {
    padding-left: 3px;
}

.breadcrumbs ul li:last-child a {
    color: #707070;
}

.breadcrumbs ul li a {
    font-family: regularFont;
}

@media all and (max-width: smallScreen) {
    .breadcrumbs {
        overflow: hidden;
    }

    .breadcrumbs ul li:first-child div {
        display: none;
    }

    .breadcrumbs ul li:first-child a:after {
        content: '...';
    }
}

@media all and (max-width: 360px) {
    .breadcrumbs {
        display: none;
        height: 10px;
    }
}
