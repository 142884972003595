/* Weight Entry Styling */
.entry {
    width: 100%;
}

.input {
    width: 120px;
}

.fieldDescription {
    composes: fieldDescription from './common.module.css';
}
