import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Nav from '../nav';
import Slider from '../slider';

import styles from '../../css/quote-result.module.css';

import CostForCoverage from './cost-for-coverage';
import SelectAmount from './select-amount';
import ShareOrSave from './share-or-save';
import NextSteps from './next-steps';

import {
    QUOTE_FACE_VALUE,
    QUOTE_TERM_RATES
} from '../../constants';
import Disclosure from './disclosure';


/* The ranges are cumulative. This specifies the different scales the slider
 * should use. The total value of the slider is:
 *      min + SUM((increments * stepSize) for each range)
 */
const defaultRanges = [
    { increments: 16, stepSize: 50000 }, // 16 * $50,000 = $800,000
    { increments: 20, stepSize: 100000 }, // 20 * $100,000 =  $2,000,000
    { increments: 8, stepSize: 250000 } // 8 * $250,000 = $2,000,000
];

const valueLookup = (ranges, { min, position }) => {
    const { value } = ranges.reduce(
        (total, { increments, stepSize }) => {
            if (position <= total.increments) {
                return total;
            }

            const incrementsFromRange = Math.min(
                position - total.increments - 1,
                increments
            );

            return {
                value: total.value + (incrementsFromRange * stepSize),
                increments: total.increments + incrementsFromRange
            };
        },
        { value: 0, increments: 0 }
    );

    return value + min;
};

const valueMap = ranges => (state => valueLookup(ranges, state));

const insertValue = (ranges, min, max, value) => {
    let totalValue = min;
    let totalIncrements = 1; // There's always at least one increment.
    let valuePos = 0;

    const newRanges = ranges.map(range => {
        if (value <= totalValue) {
            // We're past the inserted value, no need to change the range.
            totalIncrements += range.increments;
            return [range];
        }

        const { increments, stepSize } = range;

        const rangeValue = increments * stepSize;

        if ((totalValue + rangeValue) === max && value >= max) {
            totalValue += rangeValue;
            totalIncrements += range.increments;
            valuePos = totalIncrements;
            return [range];
        }

        if (totalValue + rangeValue <= value) {
            // This value is at the end of the current range, no need to change
            // the range.
            totalValue += rangeValue;
            totalIncrements += range.increments;
            return [range];
        }

        // The new value falls _within_ (not at the boundary of) the current
        // range. We'll insert it where it goes which means we'll return _four_
        // ranges.
        const valueFromRange = value - totalValue;
        const valueIncrements = Math.floor(valueFromRange / stepSize);
        const offset = valueFromRange - (valueIncrements * stepSize);

        valuePos = totalIncrements + valueIncrements + 1;
        totalValue += rangeValue;

        // Because we're splitting one increment into two, add an extra
        // increment.
        totalIncrements += range.increments + 1;

        return [
            { increments: valueIncrements, stepSize },
            { increments: 1, stepSize: offset },
            { increments: 1, stepSize: stepSize - offset },
            { increments: increments - valueIncrements - 1, stepSize }
        ];
    }).reduce((acc, val) => acc.concat(val), []);

    return { ranges: newRanges, totalIncrements, valuePos };
};

const insertCalculatorValue = (state, value) => {
    const { min, max } = Slider.State.getBounds(
        state[QUOTE_FACE_VALUE]
    );

    const { ranges, totalIncrements, valuePos } = insertValue(
        defaultRanges, min, max, value
    );

    state[QUOTE_FACE_VALUE] = Slider.State.setCustomValueGetter(
        Slider.State.setPosition(
            Slider.State.setBounds(
                state[QUOTE_FACE_VALUE], min, max, totalIncrements
            ),
            valuePos
        ),
        valueMap(ranges)
    );

    return state;
};

const QuoteResultState = ({
    Initial: () => ({
        [QUOTE_FACE_VALUE]: Slider.State.Initial({
            min: 200000,
            max: 5000000,
            increments: 45,
            position: 7,
            valueGetter: valueMap(defaultRanges)
        })
    }),
    insertCalculatorValue,
    Actions: (update) => ({
        ...Slider.State.Actions(update),
        backToQuoteResult: () => update({
            nav: state => Nav.State.setLabelAction(
                state,
                'Return to quote',
                history => {
                    history.push('/life-calculator/quote/about');
                    update({ nav: Nav.State.resetState(state) });
                }
            )
        })
    })
});


const QuoteResultComponent = ({ state, actions }) => {
    if (!state[QUOTE_TERM_RATES]) {
        return <Redirect to="/life-calculator/quote/about" />;
    }

    return (
        <div className={styles.quoteResult}>
            <SelectAmount state={state} actions={actions} />
            <CostForCoverage title="Cost for coverage"
                state={state}
                actions={actions}
            />
            <NextSteps state={state} actions={actions} />
            <ShareOrSave state={state} actions={actions} />
            <Disclosure />
        </div>
    );
};


QuoteResultComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.shape({
        [QUOTE_TERM_RATES]: PropTypes.objectOf(PropTypes.string).isRequired,
        [QUOTE_FACE_VALUE]: Slider.statePropTypes.isRequired
    }).isRequired
};


export default { Component: QuoteResultComponent, State: QuoteResultState };
