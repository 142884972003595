@value principalBlue, pdsDarkGray, pdsMediumLightGray, pdsMediumGray, pdsMediumDarkGray, boldFont from './common.module.css';

/* Slider Styling */
.slider {
    width: 100%;
    padding: 0 10px 0 10px;
    flex-direction: column;
    display: flex;
}

.label,
.control,
.scaleLabel,
.pointer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.label {
    justify-content: center;
    margin-right: 10px;
    margin-left: 10px;
    user-select: none;
}

.labelText {
    color: pdsDarkGray;
    font-family: boldFont;
    font-size: 19px;
}


/* Slider Labels Styling */
.scaleLabel {
    user-select: none;
}

.labelRight {
    margin-left: auto;
    user-select: none;
}

.scaleText {
    font-size: 14px;
    color: pdsMediumDarkGray;
}

/* Slider "Bar" Styling */
.bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.bar:focus {
    outline: none;
}

.bar:focus > div {
    border: 1px solid rgb(255, 146, 49);
}

.barMarker {
    background: pdsMediumLightGray;
    height: 12px;
    width: 12px;
    border-radius: 6px;
}

.barScale {
    background: pdsMediumLightGray;
    height: 6px;
    flex: 1;
    /*margin-left: -1px;*/
    margin-right: -1px;
}

.left div {
    margin-left: -1px;
    background: principalBlue;
}


/* Slider Position Marker Styling */
.marker {
    flex-direction: column;
    background: principalBlue;
    cursor: pointer;
    height: 36px;
    width: 36px;
    border-radius: 18px;
    box-shadow: 0px 3px 3px #00000029;
}

.marker:focus {
    outline: none;
    border: 1px solid rgb(255, 146, 49);
}

/* Slider Pointer Styling */
.pointer {
    margin-top: 5px;
}

.pointerLines {
    height: 25px;
    width: 25px;
    margin: 0 0 25px 23px;
    padding: 0;
    border: 0;
    border-left: 2px dashed pdsMediumGray;
    border-bottom: 2px dashed pdsMediumGray;
}

.pointerLabel {
    margin-left: 5px;
    color: pdsMediumDarkGray;
    font-size: 14px;
}

