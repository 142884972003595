import React from 'react';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';
import stateHelpers from '../../state-helpers';

import Label from '../label';
import NumberInput from '../number-input';
import ErrorMessage from '../error-message';

import styles from '../../css/height.module.css';

const validate = state => {
    state.feet = NumberInput.State.validate(state.feet);
    state.inches = NumberInput.State.validate(state.inches);

    state.hasError = false;
    state.errorMsg = null;

    if (!NumberInput.State.isValid(state.feet)) {
        const { errorMsg } = NumberInput.State.getErrorState(state.feet);
        state.hasError = true;
        state.errorMsg = errorMsg;

        return state;
    }

    if (!NumberInput.State.isValid(state.inches)) {
        const { errorMsg } = NumberInput.State.getErrorState(state.inches);
        state.hasError = true;
        state.errorMsg = errorMsg;

        return state;
    }

    return state;
};

const set = (state, heightInInches) => {
    const feet = Math.floor(heightInInches / 12);
    const inches = (heightInInches / 12 - feet) * 12;

    state.feet = NumberInput.State.set(state.feet, feet);
    state.inches = NumberInput.State.set(state.inches, inches);

    return validate(state);
};

const getErrorState = state => {
    const feet = NumberInput.State.getErrorState(state.feet);
    if (feet.hasError) {
        return {
            hasError: true,
            errorMsg: feet.errorMsg
        };
    }

    const inches = NumberInput.State.getErrorState(state.inches);
    if (inches.hasError) {
        return {
            hasError: true,
            errorMsg: inches.errorMsg
        };
    }

    return {
        hasError: false,
        errorMsg: null
    };
};


const HeightInputState = {
    Initial: id => {
        const state = {
            ...NumberInput.State.Initial('feet', {
                title: 'Feet',
                min: 4,
                max: 7
            }),
            ...NumberInput.State.Initial('inches', {
                title: 'Inches',
                min: 0,
                max: 11,
                allowNull: true
            })
        };

        if (id !== null && id !== undefined) {
            return { [id]: state };
        }

        return state;
    },
    getHeight: ({ feet, inches }) => (
        (NumberInput.State.get(feet) * 12) + NumberInput.State.get(inches)
    ),
    set,
    isValid: ({ feet, inches }) => (
        NumberInput.State.isValid(feet) && NumberInput.State.isValid(inches)
    ),
    Actions: (update) => ({
        validateHeight: id => update({ [id]: state => validate(state) }),
        heightActions: stateHelpers.mapActions(
            update, NumberInput.State.Actions
        )
    })
};


const HeightInputComponent = ({ state, id, actions }) => {
    const { hasError, errorMsg } = getErrorState(state[id]);

    return (
        <div>
            <Label.Component htmlFor="height"
                label="Height"
                required
            />
            <div className={styles.entry} aira-describedby="height">
                <div className={styles.feet}>
                    <label htmlFor="number-feet"
                        className={styles.fieldDescription}
                    >
                        Feet
                    </label>
                    <NumberInput.Component id="feet"
                        state={state[id]}
                        actions={actions.heightActions(id)}
                        hideErrors
                        dataCy="feet"
                    />
                </div>
                <div className={styles.inches}>
                    <label htmlFor="number-inches"
                        className={styles.fieldDescription}
                    >
                        Inches
                    </label>
                    <NumberInput.Component id="inches"
                        state={state[id]}
                        actions={actions.heightActions(id)}
                        hideErrors
                        dataCy="inches"
                    />
                </div>
            </div>
            <ErrorMessage hasError={hasError} errorMsg={errorMsg} />
        </div>
    );
};


HeightInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.shape({
        validateHeight: PropTypes.func.isRequired,
        heightActions: PropTypes.func.isRequired
    }).isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        PropTypes.shape({
            feet: NumberInput.statePropTypes,
            inches: NumberInput.statePropTypes
        }).isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: HeightInputState,
    Component: HeightInputComponent
};
