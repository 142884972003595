import React from 'react';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';

import Label from '../label';
import NumberInput from '../number-input';
import ErrorMessage from '../error-message';

import styles from '../../css/weight.module.css';

const WeightInputState = {
    Initial: id => NumberInput.State.Initial(id, {
        title: 'Weight',
        min: 0,
        max: 650,
        allowNull: false
    }),
    getWeight: NumberInput.State.get,
    set: NumberInput.State.set,
    isValid: NumberInput.State.isValid,
    Actions: update => {
        const numberInputActions = NumberInput.State.Actions(update);

        return {
            ...numberInputActions,
            validateWeight: numberInputActions.validateNumber
        };
    }
};


const WeightInputComponent = ({ id, state, actions }) => (
    <div className={styles.entry}>
        <Label.Component htmlFor={`number-${id}`}
            label="Weight"
            required
        />
        <div className={styles.input}>
            <div className={styles.fieldDescription}>Pounds</div>
            <NumberInput.Component id={id} state={state} actions={actions} dataCy="pounds" />
        </div>
        <ErrorMessage hasError={state[id].hasError}
            errorMsg={state[id].errorMsg}
        />
    </div>
);

WeightInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: NumberInput.actionPropTypes.isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        NumberInput.statePropTypes.isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: WeightInputState,
    Component: WeightInputComponent
};
