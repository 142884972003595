@value regularFont from './common.module.css';

.tobaccoSelect {
    display: flex;
    flex-direction: column;
}

.tobaccoUserLabel {
    font-family: regularFont;
    padding-bottom: 20px;
}

.years {
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: column;
}

.yearsLabel {
    font-family: regularFont;
    padding: 10px 0px;
}

.yearsOption {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 0px;
}
