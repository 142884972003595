@value pdsDarkGray, boldFont from './common.module.css';

/* SVG Pie Chart Styling */
.svgpie {
    position: relative;
    display: flex;
}

.svglabel {
    position: absolute;
    width: 90%;
    height: 90%;
    top: calc(5%);
    left: calc(5%);
    color: pdsDarkGray;

    display: flex;
    align-items: center;
    justify-content: center;
}

.svgImgLabel {
    overflow: hidden;
    width: 90%;
    height: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.svgImgLabel img {
    max-width: 60%;
    max-height: 60%;
}

/* Pie Chart Styling */
.pieChart {
    position: relative;
    height: 250px;
    width: 250px;
}

.sliceHold {
    position: absolute;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    clip: rect(0px, 250px, 250px, 125px);
}

.sliceHold.large {
    clip: rect(auto, auto, auto, auto);
}

.sliceHold .slice {
    transition: all 1s;
    position: absolute;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    clip: rect(0px, 125px, 250px, 0px);
}

.sliceHold .slice.filler {
    transform: rotate(180deg);
}

.centerDisc {
    position: absolute;
    background: #E7E7E7;
    height: calc(80%);
    width: calc(80%);
    top: calc(10%);
    left: calc(10%);
    border-radius: 50%;
}

.centerContent {
    position: absolute;
    top: calc(15%);
    height: 70%;
    width: 100%;
    border-radius: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    color: pdsDarkGray;
}

/* Pie Chart Key Styling */
div.key {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
}

div.keyItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

div.keySwatch {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

div.keyLabel {
    width: 150px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
}

div.keyTitle {
    flex: 1;
    color: pdsDarkGray;
}

div.keyAmount {
    flex: 1;
    color: pdsDarkGray;
    font-family: boldFont;
    font-size: 20px;
}
