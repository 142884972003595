import React from 'react';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';

import Label from '../label';
import ErrorMessage from '../error-message';

import styles from '../../css/gender.module.css';


const validate = state => {
    if (state.gender === 'Male' || state.gender === 'Female') {
        state.isValid = true;
        state.hasError = false;
        state.errorMsg = null;
        return state;
    }

    state.isValid = false;
    state.hasError = true;
    state.errorMsg = 'Select a gender.';

    return state;
};

const set = (state, input) => {
    state.gender = input;
    return validate(state);
};

const GenderSelectState = {
    Initial: () => ({
        gender: null,
        isValid: false,
        hasError: false,
        errorMsg: null
    }),
    getGender: ({ gender }) => (
        gender
    ),
    set,
    isValid: state => state.isValid,
    Actions: update => ({
        validateGender: id => update({ [id]: state => validate(state) }),
        setGender: (id, gender) => (
            update({ [id]: state => set(state, gender) })
        )
    })
};


const GenderOption = ({ state, id, actions, value, label }) => (
    <div className="pds-radio">
        <input type="radio"
            name={`radio-${id}`}
            value={value}
            id={`radio-${id}-${value}`}
            checked={state[id].gender === value}
            onChange={() => actions.setGender(id, value)}
        />
        <label htmlFor={`radio-${id}-${value}`}>{ label }</label>
    </div>
);


GenderOption.propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    actions: PropTypes.shape(
        { setGender: PropTypes.func.isRequired }
    ).isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        PropTypes.shape({ gender: PropTypes.string }).isRequired
    ) /* eslint-enable react/require-default-props */
};


const GenderSelectComponent = ({ state, id, actions }) => (
    <div>
        {/* eslint-disable-next-line */}
        <Label.Component htmlFor={`radio-${id}`}
            label="Gender"
            required
        />
        <div className={styles.options}>
            <GenderOption state={state}
                id={id}
                actions={actions}
                value="Male"
                label="Male"
            />
            <GenderOption state={state}
                id={id}
                actions={actions}
                value="Female"
                label="Female"
            />
        </div>
        <ErrorMessage hasError={state[id].hasError}
            errorMsg={state[id].errorMsg}
        />
    </div>
);


GenderSelectComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.shape(
        { setGender: PropTypes.func.isRequired }
    ).isRequired,
    /* eslint-disable react/require-default-props */
    state: stateChecker(
        PropTypes.shape(
            { gender: PropTypes.string }
        ).isRequired
    ) /* eslint-enable react/require-default-props */
};


export default {
    State: GenderSelectState,
    Component: GenderSelectComponent
};
