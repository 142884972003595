import React from 'react';
import PropTypes from 'prop-types';
import { webAnalytics as wa } from '@principal/web-analytics';
import NameEntry from '../name-input';
import TextEntry from '../text-input';
import EmailEntry from '../email-input';
import PhoneEntry from '../phone-input';
import ZipCodeEntry from '../zip';
import AppointmentEntry from '../appointment-input';
import MultipleEntry from '../multiple-input';
import Label from '../label';

import QuoteHero from '../../img/quote-hero.png';

import styles from '../../css/contact-form.module.css';

import {
    CONTACT_NAME,
    CONTACT_EMAIL,
    CONTACT_PHONE,
    CONTACT_ZIP_CODE,
    CONTACT_HOUR_OPTIONS,
    CONTACT_HOUR_KEY,
    CONTACT_APPOINTMENT
} from '../../constants';

import ErrorMessage from '../error-message';
import ContactValues from '../quote/sf-contact-data';

const isValid = (advisor, state) => {
    const scheduleACallValid = TextEntry.State.isValid(state[CONTACT_NAME].first)
        && TextEntry.State.isValid(state[CONTACT_NAME].last)
        && EmailEntry.State.isValid(state[CONTACT_EMAIL])
        && PhoneEntry.State.isValid(state[CONTACT_PHONE])
        && AppointmentEntry.State.isValid(state[CONTACT_APPOINTMENT]);

    if (advisor) {
        return scheduleACallValid && ZipCodeEntry.State.isValid(state[CONTACT_ZIP_CODE]);
    }
    return scheduleACallValid;
};

const validateForm = (state, actions) => {
    // Trigger validation runs.
    actions.validateText(CONTACT_NAME);
    actions.validateEmail(CONTACT_EMAIL);
    actions.validatePhone(CONTACT_PHONE);
    actions.validateNumber(CONTACT_ZIP_CODE);
    actions.validateAppointment(CONTACT_APPOINTMENT);
    return isValid(state);
};

const ScheduleACallState = ({
    Initial: () => ({
        'contact:status': 'new',
        [CONTACT_NAME]: NameEntry.State.Initial(),
        ...EmailEntry.State.Initial(CONTACT_EMAIL),
        ...PhoneEntry.State.Initial(CONTACT_PHONE),
        ...ZipCodeEntry.State.Initial(CONTACT_ZIP_CODE),
        ...AppointmentEntry.State.Initial(CONTACT_APPOINTMENT, { title: 'Date' }),
        ...MultipleEntry.State.Initial(CONTACT_HOUR_KEY, {
            options: CONTACT_HOUR_OPTIONS,
            value: 'between-8am-11am',
            title: 'Time',
            allowNull: false
        })
    }),
    isValid,
    validateForm,
    Actions: (update) => (
        {
            ...NameEntry.State.Actions(update),
            ...EmailEntry.State.Actions(update),
            ...PhoneEntry.State.Actions(update),
            ...ZipCodeEntry.State.Actions(update),
            ...MultipleEntry.State.Actions(update),
            ...AppointmentEntry.State.Actions(update),
            setContactFormStatus: (newStatus) => {
                update(state => ({ ...state, 'contact:status': newStatus }));
            },
            requestContact: (state, advisor, setContactFormStatus, actions) => {
                // Build up the request body.
                const body = ContactValues(state, advisor);
                setContactFormStatus('submitting');
                actions.showModalContent('modal', () => 'Thank You', () => <PendingSubmission />);
                fetch('/life-calculator/api/v1/task/sf-cta', {
                    method: 'POST',
                    mode: 'cors',
                    headers: { 'content-type': 'application/json' },
                    body: JSON.stringify(body)
                }).then(res => {
                    if (res.status === 200) {
                        setContactFormStatus('submitted');
                        actions.showModalContent('modal', () => 'Thank You', () => <Confirmation actions={actions} />);
                    } else {
                        setContactFormStatus('error');
                        actions.showModalContent(
                            'modal',
                            () => (advisor ? <span>Find a financial professional</span> : <span>Schedule a call</span>),
                            (currentState, currentActions) => <ContactForm state={currentState} actions={currentActions} advisor={advisor} />
                        );
                    }
                });
            }
        }
    )
});

const PendingSubmission = () => (
    <div>
        <img className={styles.confirmationHero} src={QuoteHero} alt="" />
        <div className={`pds-typography-small ${styles.confirmationMsg}`}>
            Your request is being processed. Please wait.
        </div>
    </div>
);

const Confirmation = ({ actions }) => (
    <div>
        <img className={styles.confirmationHero} src={QuoteHero} alt="" />
        <div className={`pds-typography-small ${styles.confirmationMsg}`}> Thank you, your request has been received. </div>
        <div className={styles.confirmationCloseBtn}>
            <button type="button"
                id="confirmation-close-button"
                className="pds-button pds-button-primary"
                onClick={() => actions.hideModal('modal')}
            >
                Close
            </button>
        </div>
    </div>
);

Confirmation.propTypes = {
    actions: PropTypes.shape().isRequired
};

const ContactForm = ({ advisor, state, actions }) => {
    return (
        <form className={styles.form}>
            <div className={styles.formRows}>
                <ErrorMessage hasError={state['contact:status'] === 'error'}
                    errorMsg="We are unable to process your request at this time. Please retry!"
                />
                <NameEntry.Component state={state}
                    id={CONTACT_NAME}
                    actions={actions}
                />
                <div className={styles.formRow}>
                    <PhoneEntry.Component state={state}
                        id={CONTACT_PHONE}
                        actions={actions}
                    />
                </div>
                <div className={styles.formRow}>
                    <EmailEntry.Component state={state}
                        id={CONTACT_EMAIL}
                        actions={actions}
                    />
                </div>
                {advisor
                && (<div className={styles.formRow}>
                    <ZipCodeEntry.Component state={state}
                        id={CONTACT_ZIP_CODE}
                        actions={actions}
                    />
                    </div>
                )}
                <Label.Component htmlFor="appointment"
                    label="When is a good time to call?"
                    required
                />
                <span className={styles.availability}>Available weekdays 7 a.m. to 7 p.m. CT</span>
                <div className={styles.formRow}>
                    <label className={styles.label} htmlFor={`multiple-${CONTACT_APPOINTMENT}`}>
                        Date: (MM/DD/YYYY)
                    </label>
                    <AppointmentEntry.Component state={state}
                        id={CONTACT_APPOINTMENT}
                        actions={actions}
                    />
                </div>
                <div className={styles.formRow}>
                    <label className={styles.label} htmlFor={`multiple-${CONTACT_HOUR_KEY}`}>
                        Time
                    </label>
                    <MultipleEntry.Component state={state}
                        id={CONTACT_HOUR_KEY}
                        actions={actions}
                    />
                </div>
                <div className={styles.nextButton}>
                    <button className="pds-button pds-button-primary"
                        type="submit"
                        disabled={!isValid(advisor, state)}
                        onClick={evt => {
                            evt.preventDefault();
                            actions.requestContact(state, advisor, actions.setContactFormStatus, actions);
                            window.dataLayer.push({
                                event: 'e_formInteraction',
                                eventType: 'submit',
                                formAttributes: {
                                    name: `${advisor ? 'Find a financial professional' : 'Schedule a call'}`
                                }
                            });
                            wa.trackEvent('formSubmit', {
                                type: 'contact-prospect',
                                lead: 'yes',
                                name: 'Find a financial professional'
                            });
                        }}
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    );
};

ContactForm.defaultProps = { advisor: false };

ContactForm.propTypes = {
    advisor: PropTypes.bool,
    actions: PropTypes.shape().isRequired,
    state: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types, max-len
};

export default {
    State: ScheduleACallState,
    Component: ContactForm
};
