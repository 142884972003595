import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '@principal/life-calculator-helpers';

import styles from '../../css/pie-chart.module.css';

const CHART_SIZE = 150;


/* Browsers will round high precision numbers to much lower precision. This
 * ensures the rounding is done "down" so that very small or very large
 * percentages are correctly rounded and displayed.
 */
const rounder = number => (
    Number.parseFloat(
        Math.trunc(number * 1000) / 1000
    ).toFixed(4)
);

const getXY = (center, radius, percent) => {
    const angle = Math.round(percent * 360) + 90;
    const radians = angle * (Math.PI / 180);

    return [
        rounder(center - (Math.cos(radians) * radius)),
        rounder(center - (Math.sin(radians) * radius))
    ];
};

const Slice = ({ size, seenPercent, portion, color }) => {
    // If this is an empty slice, bail.
    if (portion <= 0) {
        return null;
    }

    // If this is too big of a slice, bail.
    if (portion > 1) {
        return null;
    }

    const strokeWidth = size * 0.11;
    const center = Math.round(size / 2);
    const radius = center - (strokeWidth / 2);

    // If this the only slice, just draw a circle.
    if (portion === 1) {
        return (
            <circle cx={center}
                cy={center}
                r={radius}
                fill="transparent"
                strokeWidth={strokeWidth}
                stroke={color}
            />
        );
    }

    // Ok, let's draw a circle chunk, a.k.a. an arc.
    let bigSweep = 0;
    let sweep = 1;
    if (portion > 0.5) {
        // If we're over 50% of the circle, we'll want the larger arc.
        bigSweep = 1;
        sweep = 1;
    }

    const [startX, startY] = getXY(center, radius, seenPercent);
    const [endX, endY] = getXY(center, radius, seenPercent + portion);

    // M x, y
    const move = `M ${startX},${startY}`;
    // A rx, ry, x-rotation, large-sweep, sweep, end-x, end-y
    const arc = `A ${radius},${radius},0,${bigSweep},${sweep},${endX},${endY}`;

    return (
        <path d={`${move} ${arc}`}
            strokeWidth={strokeWidth}
            stroke={color}
            fill="transparent"
        />
    );
};

Slice.propTypes = {
    size: PropTypes.number.isRequired,
    seenPercent: PropTypes.number.isRequired,
    portion: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
};

const CenterCircle = ({ size }) => {
    const center = Math.round(size / 2);
    const radius = center;

    return <circle cx={center} cy={center} r={radius} fill="#E6E6E6" />;
};

CenterCircle.propTypes = { size: PropTypes.number.isRequired };

const SvgChart = ({ slices, total, size }) => {
    let seenPercent = 0;

    const chartSize = size || CHART_SIZE;

    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            width={chartSize}
            height={chartSize}
        >
            <CenterCircle size={chartSize} />
            {
                slices.map(
                    ({ value, color }) => {
                        const portion = value / total;
                        seenPercent += portion;
                        return (
                            <Slice key={color}
                                size={chartSize}
                                seenPercent={seenPercent - portion}
                                portion={portion}
                                color={color}
                            />
                        );
                    }
                )
            }
        </svg>
    );
};

SvgChart.propTypes = {
    slices: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    })).isRequired,
    total: PropTypes.number.isRequired,
    size: PropTypes.number.isRequired
};

const CenterLabel = ({ hideLabel, img, label }) => {
    if (hideLabel) {
        return null;
    }

    if (img) {
        return (
            <div className={styles.svglabel}>
                <div className={styles.svgImgLabel}>{img}</div>
            </div>
        );
    }

    return (
        <div className={styles.svglabel}>
            <div className="pds-typography-bold pds-typography-h1">
                {label}
            </div>
        </div>
    );
};

CenterLabel.defaultProps = { img: '' };

CenterLabel.propTypes = {
    hideLabel: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    img: PropTypes.node
};


const Chart = ({ slices, size, img, hideLabel }) => {
    const total = slices.reduce((last, { value }) => last + value, 0);

    const totalStr = (
        total < 1000000
            ? `$${formatNumber(total)}`
            : `$${formatNumber(Math.round(total / 10000) / 100)}m`
    );

    return (
        <div className={styles.svgpie}>
            <SvgChart slices={slices} total={total} size={size} />
            <CenterLabel hideLabel={hideLabel} img={img} label={totalStr} />
        </div>
    );
};

Chart.defaultProps = { hideLabel: false, img: '' };

Chart.propTypes = {
    slices: PropTypes.arrayOf(PropTypes.shape({
        color: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        value: PropTypes.number.isRequired
    })).isRequired,
    size: PropTypes.number.isRequired,
    hideLabel: PropTypes.bool,
    img: PropTypes.node
};

export default { Chart };
