@value principalBlue, regularFont, largeScreen from './common.module.css';

.nav {
    width: 100%;
}

.back {
    color: principalBlue;
    font-size: 14px;
    line-height: 17px;
    font-family: regularFont;
    cursor: pointer;
    display: inline-block;
    margin: 20px 0 0 10px;
    white-space: normal;
    border-radius: 30px;
    padding-left: 25px;
    padding-right: 25px;
    border-color: rgba(0,0,0,0);
    font-weight: normal;
    font-style: normal;
    background-color: transparent;
}

@media all and (min-width: largeScreen) {
    .back {
        margin-left: 30px;
    }
}

.back::before {
    font-family: "FontAwesome";
    content: "\f104";
    font-size: 26px;
    position: relative;
    top: 3px;
    margin-right: 10px;
}

.back:hover {
    text-decoration: none;
}

