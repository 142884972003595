import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '@principal/life-calculator-helpers';

import stateChecker from '../../state-checker';


// TODO: Programmatically get the separator
const THOUSANDS_SEPARATOR_REGEXP = /,/g;


const numberValid = (min, max, value) => {
    if (!Number.isFinite(value)) {
        return false;
    }

    if (min !== null && value < min) {
        return false;
    }

    if (max !== null && value > max) {
        return false;
    }

    return true;
};


const validate = state => {
    if (state.value === null) {
        if (!state.allowNull) {
            state.isValid = false;
            state.hasError = true;
            state.errorMsg = `${state.title} can not be empty`;
            return state;
        }

        state.isValid = true;
        state.hasError = false;
        state.errorMsg = null;
        return state;
    }

    const value = parseInt(
        state.value.replace(THOUSANDS_SEPARATOR_REGEXP, '')
    );

    if (!Number.isFinite(value)) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `Please enter a valid ${state.title}`;
        return state;
    }

    if (state.min !== null && value < state.min) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `${state.title} must be greater than ${state.min}`;
        return state;
    }

    if (state.max !== null && value > state.max) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `${state.title} must be less than ${state.max}`;
        return state;
    }


    state.isValid = true;
    state.hasError = false;
    state.errorMsg = null;

    return state;
};

const set = (state, input) => {
    // QUESTION: Should we check allowNull === true here? It isn't clear if
    // we should? I guess we'd just prevent them clearing the input if null is
    // _not_ allowed then? In this case we'll instead show a validation error
    // on blur. I think that results in better data-entry UX.
    if (input === '') {
        state.value = null;
        return validate(state);
    }

    if (state.allowThousandsSep) {
        input = `${input}`.replace(THOUSANDS_SEPARATOR_REGEXP, '');
    }

    const value = parseInt(input);
    if (numberValid(state.min, state.max, value)) {
        state.value = state.allowThousandsSep
            ? formatNumber(value)
            : `${value}`;
        return validate(state);
    }

    return state;
};


const NumberInputState = {
    Initial: (id, userParams) => {
        const params = {
            min: null,
            max: null,
            allowNull: false,
            allowThousandsSep: true,
            title: null,
            ...userParams,
            value: null // If the default value is invalid don't use it.
        };

        let state = {
            // User params
            ...params,
            // Internal state
            isValid: params.allowNull && (
                !userParams
                || (userParams.value === null || userParams.value === undefined)
            ),
            hasError: false,
            errorMsg: null
        };

        if (userParams
            && (userParams.value !== null || userParams.value !== undefined)
        ) {
            state = set(state, userParams.value);
        }

        if (id !== null && id !== undefined) {
            return { [id]: state };
        }

        return state;
    },
    set,
    get: ({ value }) => (
        value === null
            ? null
            : parseInt(
                value.replace(THOUSANDS_SEPARATOR_REGEXP, '')
            )
    ),
    validate,
    isValid: ({ isValid }) => isValid,
    getErrorState: ({ hasError, errorMsg }) => ({ hasError, errorMsg }),
    isEmpty: ({ value }) => (value === null || value.length === 0),
    Actions: update => ({
        validateNumber: id => update({ [id]: state => validate(state) }),
        setNumber: (id, input) => update({ [id]: state => set(state, input) })
    })
};


const NumberInputComponent = ({
    id,
    state,
    actions,
    onChange,
    onFocusChange,
    placeholder,
    required,
    ariaLabel,
    dataCy
}) => (
    <input className="number-entry"
        type={state[id].type || 'text'}
        inputMode="numeric"
        pattern="[0-9]*"
        id={`number-${id}`}
        placeholder={placeholder}
        aria-label={ariaLabel}
        value={state[id].value || ''}
        aria-required={required}
        data-cy={dataCy}
        onChange={(evt) => {
            actions.setNumber(id, evt.target.value);
            if (onChange) { onChange(evt); }
        }}
        onFocus={(evt) => {
            if (onFocusChange) { onFocusChange(true, evt); }
        }}
        onBlur={(evt) => {
            actions.validateNumber(id);
            if (onFocusChange) { onFocusChange(false, evt); }
        }}
    />
);

NumberInputComponent.defaultProps = {
    onChange: null,
    onFocusChange: null,
    placeholder: '',
    ariaLabel: '',
    required: false,
    dataCy: ''
};

const actionPropTypes = PropTypes.shape(
    {
        setNumber: PropTypes.func.isRequired,
        validateNumber: PropTypes.func.isRequired
    }
);

const statePropTypes = PropTypes.shape(
    {
        value: PropTypes.string,
        isValid: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
        errorMsg: PropTypes.string
    }
);

NumberInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: actionPropTypes.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        statePropTypes.isRequired
    ),
    onChange: PropTypes.func,
    onFocusChange: PropTypes.func,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    required: PropTypes.bool,
    dataCy: PropTypes.string
};

export default {
    State: NumberInputState,
    Component: NumberInputComponent,
    statePropTypes,
    actionPropTypes
};
