@value principalBlue, pdsDarkGray, pdsMediumLightGray, pdsMediumDarkGray, regularFont, boldFont, smallScreen from './common.module.css';

/* Calc Results */
.resultsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 725px;
}

.sectionTitle {
    composes: sectionTitle from './common.module.css';
}

.nextStepsTitle {
    composes: sectionSubtitle from './common.module.css';
    margin-top: 45px;
    font-size: 20px;
    font-family: boldFont;
}

/* Chart Section */
.chartSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px solid pdsMediumLightGray;
    border-radius: 2px;
    padding: 20px 20px 30px 20px;
}

.chartSection .sectionTitle {
    font-size: 24px;
}

.editAnchor {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.chart {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-evenly;

    width: 100%;
    max-width: 600;
}

.pie {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.nextSteps {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;

    max-width: 600px;
    width: 100%;
}

.nextStepsColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 300px;
    margin: 0;
    padding: 15px 10px;
    align-items: center;
    justify-content: stretch;
}

.nextStepsColumn:first-child {
    border-right: 1px solid #E7E7E7;
}


.nextStepsLabel {
    width: 100%;
    min-height: 50px;
    text-align: center;
    font-size: 16px;
    color: pdsDarkGray;
}

.nextStepsActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.nextStepsActions > * {
    margin: 10px 0;
}

.nextStepsButtons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdfButton::before {
    font-family: "FontAwesome";
    content: "\f1c1";
    margin-right: 5px;
}

.linkButton::before {
    font-family: "FontAwesome";
    content: "\f0c1";
    margin-right: 5px;
}

.pdfButton, .linkButton, .getQuoteButton {
    padding: 12px 20px;
}

@media all and (max-width: smallScreen) {
    .nextSteps {
        flex-direction: column;
    }

    .nextStepsColumn:first-child {
        border-right: 0;
        border-bottom: 1px solid #E7E7E7;
    }

    .nextStepsColumn {
        flex: 1;
        margin: 0;
        padding: 15px 5px;
    }
}

/* Forms Section */
.formsSection {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: pdsDarkGray;
}

.howTitle {
    composes: sectionSubtitle from './common.module.css';
    font-family: boldFont;
}

.forms {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    justify-content: space-between;
    width: 100%;
}

@media all and (max-width: 730px) {
    .forms {
        flex-direction: column;
        align-items: center;
    }
}

.form {
    margin-bottom: 30px;
    width: 345px;
    border: 1px solid pdsMediumLightGray;
    border-radius: 2px;
    padding: 15px;

    display: flex;
    flex-direction: column;
}

@media all and (max-width: smallScreen) {
    .form {
        width: 100%;
    }
}

.titleRow {
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
}

.title {
    flex: 1;
    font-family: regularFont;
    font-size: 22px;
}

.chartTitle {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.controls {
    color: principalBlue;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.controls > span {
    margin-right: 10px;
    font-family: boldFont;
}

.controls > img {
    max-width: 20px;
    max-height: 20px;
}

.spacerRow {
    flex: 1;
}

.totalRow {
    width: 100%;
    margin-top: 20px;
    padding-top: 15px;
    display: flex;
    flex-direction: row;
    border-top: 1px solid pdsMediumDarkGray;
}

.totalLabel {
    flex: 1;
}

.totalAmount {
    font-family: boldFont;
}

.row {
    width: 100%;
    margin-top: 12px;
    display: flex;
    flex-direction: row;
}

.label {
    flex: 1;
}


/* Dollars Row Formatting */
.dollarsRow {
    margin: 0 10px;
}

.dollarsEditRow {
    margin: 0 10px;
    width: 115px;
    border-bottom: 1px dashed principalBlue;
}

.dollarsEditRow > div > div {
    border: 0;
    height: calc(100% - 2px);
    font-size: 20px;
}

.dollarsEditRow input {
    text-align: right;
    color: principalBlue;
}

.dollarsEditRow > div > * {
    margin-bottom: -1px;
}


/* Multiplier Row Formatting */
.multiplierRow,
.multiplierEditRow {
    margin: 0 10px;
    width: 115px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.multiplierRow {
    justify-content: flex-end;
}

.multiplierRow > div {
    padding-left: 5px;
}

.multiplier {
    font-size: 18px;
}

.multiplierEditRow .multiplier {
    flex: 1;
}

.multiplierEditRow input {
    text-align: right;
    border: 0;
    border-bottom: 1px dashed principalBlue;
    font-size: 20px;
    color: principalBlue;
    outline: none;

    width: 50px;
    height: calc(100% - 2px);
}

.multiplierEditRow input:active,
.multiplierEditRow input:focus,
.multiplierEditRow input:hover {
    border: 0;
    border-bottom: 1px dashed principalBlue;
    outline: none;
}

/* Percentage Row Formatting */
.percentageRow,
.percentageEditRow {
    margin: 0 10px;
    width: 115px;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.percentageRow {
    justify-content: flex-end;
}

.percentageRow > div:first-child {
    padding-right: 5px;
}

.percentageEditRow .multiplier {
    flex: 1;
}

.percentage {
    font-size: 18px;
    margin-left: 2px;
}

.percentageInput {
    border-bottom: 1px dashed principalBlue;
    width: 50px;

    display: flex;
    flex-direction: row;
}

.percentageInput input {
    text-align: right;
    padding-left: 0;
    border: 0;
    font-size: 20px;
    color: principalBlue;
    outline: none;

    height: calc(100% - 2px);
}

.percentageInput input:active,
.percentageInput input:focus,
.percentageInput input:hover {
    border: 0;
    outline: none;
}

/* General */
.nextButton {
    composes: nextButton from './common.module.css';
}
