@value pdsDarkGray, pdsWhite, principalBlue, pdsMediumDarkGray, pdsMediumLightGray, smallScreen, largeScreen, hugeScreen from './common.module.css';

/* Calculator Options Select */
.selector {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
}

.sectionTitle {
    composes: sectionTitle from './common.module.css';
    margin-top: 20px;
    font-size: 32px;
}

.sectionSubtitle {
    composes: sectionSubtitle from './common.module.css';
    font-size: 16px;
}

.nextButton {
    composes: nextButton from './common.module.css';
}

.option {
    margin: 10px 5px 10px 5px;
    display: flex;
    border-radius: 6px;
    border-color: pdsMediumLightGray;
    border-width: 1px;
    border-style: solid;
    flex-direction: row;
    flex: 1;
    min-height: 115px;
    cursor: pointer;
    user-select: none;
}

.optionSelected {
    border-color: principalBlue;
}


.icon {
    width: 90px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon > img {
    width: 100%;
}

.body {
    flex: 1;
    padding: 30px 0px 0px 20px;
}

.body p {
    padding-bottom: 10px;
}

.title {
    font-size: 24px;
    line-height: 24px;
    color: pdsDarkGray;
    margin: 0 0 10px 0;
}

.subtitle {
    margin: 0;
    font-size: 14px;
    color: pdsMediumDarkGray;
    line-height: 1.25em;
}

.checkboxCol {
    width: 20px;
    margin: 15px 15px 0 0;
}

.checkboxCol input {
    appearance: none;
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
}

.checkbox {
    width: 20px;
    height: 20px;
    border: 1px solid principalBlue;
    border-radius: 3px;
    background-color: pdsWhite;
    margin: 0;
}

.checkboxChecked {
    background-size: 90%;
    background-position: 2px;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3E%3Cpath fill='%230091da' d='M26.109 8.844c0 0.391-0.156 0.781-0.438 1.062l-13.438 13.438c-0.281 0.281-0.672 0.438-1.062 0.438s-0.781-0.156-1.062-0.438l-7.781-7.781c-0.281-0.281-0.438-0.672-0.438-1.062s0.156-0.781 0.438-1.062l2.125-2.125c0.281-0.281 0.672-0.438 1.062-0.438s0.781 0.156 1.062 0.438l4.594 4.609 10.25-10.266c0.281-0.281 0.672-0.438 1.062-0.438s0.781 0.156 1.062 0.438l2.125 2.125c0.281 0.281 0.438 0.672 0.438 1.062z'%3E%3C/path%3E%3C/svg%3E");
}

@media all and (max-width: smallScreen) {
    .sectionTitle {
        composes: sectionTitle from './common.module.css';
        font-size: 24px;
    }

    .icon {
        width: 75px;
    }

    .body {
        padding: 20px 0px 0px 10px;
    }

    .checkboxCol {
        width: 20px;
        height: 20px;
        margin: 10px 10px 0 0;
    }
}

@media all and (min-width: largeScreen) {
    .options {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 225px;
        height: 210px;
    }

    .icon {
        height: 70px;
    }

    .body {
        padding: 0;
        margin: 10px 10px 5px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .title {
        font-size: 22px;
    }

    .subtitle {
        font-size: 14px;
    }

    .checkboxCol {
        width: 20px;
        height: 20px;
        margin: 10px 10px 0 0;
        order: -1;
        align-self: flex-end;
    }
}

@media all and (min-width: hugeScreen) {
    .selector {
        max-width: 1000px;
    }
    .option {
        width: 275px;
        height: 235px;
    }

    .icon {
        height: 90px;
    }

    .body {
        margin: 10px 20px 5px 20px;
    }

    .title {
        font-size: 24px;
    }

    .subtitle {
        font-size: 16px;
    }

    .checkboxCol {
        margin: 20px 20px 0 0;
    }
}
