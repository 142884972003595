import React, { useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import shared from '@principal/life-calculator-helpers';
import copy from 'copy-to-clipboard';
import { webAnalytics as wa } from '@principal/web-analytics';
import stateChecker from '../../state-checker';

import PieChart from '../pie-chart';
import SvgPie from '../pie-chart/pie-chart-svg';

import EditIcon from '../../img/edit.png';
import CancelEditIcon from '../../img/edit-cancel.png';

import DollarInput from '../dollar-input';
import NumberInput from '../number-input';
import Slider from '../slider';

import calculator from '../../calculator';

import styles from '../../css/calc-results.module.css';

import ErrorMessage from '../error-message';

import {
    CALC_OPTIONS_PAGE,
    INFO_PAGES_COMPLETE,

    REPLACE_INCOME_AMOUNT,
    REPLACE_INCOME_YEARS,
    REPLACE_INCOME_GROWTH_RATE,
    REPLACE_INCOME_MULTIPLIER,

    COVER_MORTGAGE_KEY,
    COVER_STUDENT_LOAN_KEY,
    COVER_OTHER_KEY,

    FUND_TYPE_KEY,
    FUND_NUMBER_KEY,
    FUND_TYPE_COST_KEY,
    FUND_TYPE_YEARS,

    FINAL_EXPENSES_BURIAL,
    FINAL_EXPENSES_FUNERAL,

    CALC_RESULTS_PIE,
    QUOTE_TERM_RATES
} from '../../constants';

const REPLACE_INCOME_EDIT = 'calc:results:replace_edit';
const REPLACE_INCOME_AMOUNT_TEMP = 'calc:results:replace_amount';
const REPLACE_INCOME_YEARS_TEMP = 'calc:results:replace_years';
const REPLACE_INCOME_GROWTH_TEMP = 'calc:results:replace_growth';

const COVER_DEBT_EDIT = 'calc:results:cover_edit';
const COVER_MORTGAGE_TEMP = 'calc:results:cover_mortgage';
const COVER_STUDENT_LOAN_TEMP = 'calc:results:cover_studentloans';
const COVER_OTHER_TEMP = 'calc:results:cover_other';

const FUND_EDUCATION_EDIT = 'calc:results:fund_edit';
const FUND_NUMBER_TEMP = 'calc:results:fund_number';
const FUND_YEARS_TEMP = 'calc:results:fund_years';
const FUND_COST_TEMP = 'calc:results:fund_cost';


const FINAL_EXPENSES_EDIT = 'calc:results:final_edit';
const FINAL_EXPENSES_BURIAL_TEMP = 'calc:results:final_burial';
const FINAL_EXPENSES_FUNERAL_TEMP = 'calc:results:final_funeral';


const setDollarInput = (state, toInput, fromInput) => {
    state[toInput] = DollarInput.State.set(
        state[toInput],
        DollarInput.State.get(state[fromInput])
    );

    return state;
};

const setNumberInput = (state, toInput, fromInput) => {
    state[toInput] = NumberInput.State.set(
        state[toInput],
        NumberInput.State.get(state[fromInput])
    );

    return state;
};


const buildBody = state => ({
    [`${CALC_OPTIONS_PAGE}:choices`]: state[CALC_OPTIONS_PAGE].choices,
    [REPLACE_INCOME_AMOUNT]: DollarInput.State.get(
        state[REPLACE_INCOME_AMOUNT]
    ),
    [REPLACE_INCOME_YEARS]: NumberInput.State.get(state[REPLACE_INCOME_YEARS]),
    [REPLACE_INCOME_MULTIPLIER]: state[REPLACE_INCOME_MULTIPLIER],
    [REPLACE_INCOME_GROWTH_RATE]: NumberInput.State.get(
        state[REPLACE_INCOME_GROWTH_RATE]
    ),
    [COVER_MORTGAGE_KEY]: DollarInput.State.get(state[COVER_MORTGAGE_KEY]),
    [COVER_STUDENT_LOAN_KEY]: DollarInput.State.get(
        state[COVER_STUDENT_LOAN_KEY]
    ),
    [COVER_OTHER_KEY]: DollarInput.State.get(state[COVER_OTHER_KEY]),
    [FUND_NUMBER_KEY]: NumberInput.State.get(state[FUND_NUMBER_KEY]),
    [FUND_TYPE_KEY]: state[FUND_TYPE_KEY],
    [FUND_TYPE_COST_KEY]: Slider.State.getValue(state[FUND_TYPE_COST_KEY]),
    [FUND_TYPE_YEARS]: NumberInput.State.get(state[FUND_TYPE_YEARS]),
    [FINAL_EXPENSES_BURIAL]: DollarInput.State.get(
        state[FINAL_EXPENSES_BURIAL]
    ),
    [FINAL_EXPENSES_FUNERAL]: DollarInput.State.get(
        state[FINAL_EXPENSES_FUNERAL]
    )
});

const fetchId = state => {
    const body = buildBody(state);

    return fetch('/life-calculator/api/v1/calc/get-id', {
        method: 'POST',
        mode: 'cors',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify(body)
    })
        .then(res => res.json())
        .then(data => {
            if (!data.success) {
                throw new Error('Failed to get id');
            }

            return data.id;
        });
};

const CalcResultsState = ({
    Initial: () => ({
        [REPLACE_INCOME_EDIT]: false,
        ...NumberInput.State.Initial(REPLACE_INCOME_AMOUNT_TEMP, {
            min: 0,
            max: 9999999,
            title: 'Income'
        }),
        ...NumberInput.State.Initial(REPLACE_INCOME_YEARS_TEMP, {
            min: 1,
            max: 30,
            value: 5,
            title: 'Years'
        }),
        ...NumberInput.State.Initial(REPLACE_INCOME_GROWTH_TEMP, {
            min: 0,
            max: 99,
            title: 'Growth rate'
        }),

        // Cover Debt
        [COVER_DEBT_EDIT]: false,
        ...DollarInput.State.Initial(COVER_MORTGAGE_TEMP, {
            min: 0,
            max: 9999999,
            title: 'Mortgage'
        }),
        ...DollarInput.State.Initial(COVER_STUDENT_LOAN_TEMP, {
            min: 0,
            max: 9999999,
            title: 'Student loans'
        }),
        ...DollarInput.State.Initial(COVER_OTHER_TEMP, {
            min: 0,
            max: 9999999,
            title: 'Other debt'
        }),

        [FUND_EDUCATION_EDIT]: false,
        ...NumberInput.State.Initial(FUND_COST_TEMP, {
            min: 0,
            max: 200000
        }),
        ...NumberInput.State.Initial(FUND_NUMBER_TEMP, {
            value: 1,
            min: 0,
            max: 20
        }),
        ...NumberInput.State.Initial(FUND_YEARS_TEMP, {
            value: 1,
            min: 0,
            max: 20
        }),


        // Final Expenses
        [FINAL_EXPENSES_EDIT]: false,
        ...DollarInput.State.Initial(FINAL_EXPENSES_BURIAL_TEMP, {
            min: 0,
            max: 100000,
            value: 5000,
            title: 'Burial or cremation'
        }),
        ...DollarInput.State.Initial(FINAL_EXPENSES_FUNERAL_TEMP, {
            min: 0,
            max: 100000,
            value: 10000,
            title: 'Funeral'
        }),

        [CALC_RESULTS_PIE]: PieChart.State.Initial(),
        ...NumberInput.State.Initial(REPLACE_INCOME_GROWTH_RATE, {
            min: 0,
            max: 99,
            value: 3,
            title: 'Income growth rate'
        })
    }),
    Actions: update => ({
        cancelEditForm: id => update({ [id]: false }),

        editReplaceIncome: () => update(
            state => {
                state[REPLACE_INCOME_EDIT] = true;

                state = setDollarInput(
                    state, REPLACE_INCOME_AMOUNT_TEMP, REPLACE_INCOME_AMOUNT
                );

                state = setNumberInput(
                    state,
                    REPLACE_INCOME_GROWTH_TEMP,
                    REPLACE_INCOME_GROWTH_RATE
                );

                state = setNumberInput(
                    state, REPLACE_INCOME_YEARS_TEMP, REPLACE_INCOME_YEARS
                );

                return state;
            }
        ),
        saveReplaceIncome: () => update(
            state => {
                state[REPLACE_INCOME_EDIT] = false;

                state = setDollarInput(
                    state, REPLACE_INCOME_AMOUNT, REPLACE_INCOME_AMOUNT_TEMP
                );

                state = setNumberInput(
                    state,
                    REPLACE_INCOME_GROWTH_RATE,
                    REPLACE_INCOME_GROWTH_TEMP
                );

                state = setNumberInput(
                    state, REPLACE_INCOME_YEARS, REPLACE_INCOME_YEARS_TEMP
                );

                state = calculator.updateCalcResultsSlices(state);
                return state;
            }
        ),

        editCoverDebt: () => update(
            state => {
                state[COVER_DEBT_EDIT] = true;

                state = setDollarInput(
                    state, COVER_MORTGAGE_TEMP, COVER_MORTGAGE_KEY
                );
                state = setDollarInput(
                    state, COVER_STUDENT_LOAN_TEMP, COVER_STUDENT_LOAN_KEY
                );
                state = setDollarInput(
                    state, COVER_OTHER_TEMP, COVER_OTHER_KEY
                );

                return state;
            }
        ),
        saveCoverDebt: () => update(
            state => {
                state[COVER_DEBT_EDIT] = false;

                state = setDollarInput(
                    state, COVER_MORTGAGE_KEY, COVER_MORTGAGE_TEMP
                );
                state = setDollarInput(
                    state, COVER_STUDENT_LOAN_KEY, COVER_STUDENT_LOAN_TEMP
                );
                state = setDollarInput(
                    state, COVER_OTHER_KEY, COVER_OTHER_TEMP
                );

                state = calculator.updateCalcResultsSlices(state);
                return state;
            }
        ),

        editFundEducation: () => update(
            state => {
                state[FUND_EDUCATION_EDIT] = true;

                state[FUND_COST_TEMP] = DollarInput.State.set(
                    state[FUND_COST_TEMP],
                    Slider.State.getValue(state[FUND_TYPE_COST_KEY])
                );

                state = setNumberInput(
                    state, FUND_NUMBER_TEMP, FUND_NUMBER_KEY
                );
                state = setNumberInput(
                    state, FUND_YEARS_TEMP, FUND_TYPE_YEARS
                );

                return state;
            }
        ),
        saveFundEducation: () => update(
            state => {
                state[FUND_EDUCATION_EDIT] = false;

                state[FUND_TYPE_COST_KEY] = (
                    Slider.State.setPositionNearestValue(
                        state[FUND_TYPE_COST_KEY],
                        DollarInput.State.get(state[FUND_COST_TEMP])
                    )
                );

                state = setNumberInput(
                    state, FUND_NUMBER_KEY, FUND_NUMBER_TEMP
                );
                state = setNumberInput(
                    state, FUND_TYPE_YEARS, FUND_YEARS_TEMP
                );

                state = calculator.updateCalcResultsSlices(state);
                return state;
            }
        ),

        editFinalExpenses: () => update(
            state => {
                state[FINAL_EXPENSES_EDIT] = true;

                state = setDollarInput(
                    state, FINAL_EXPENSES_BURIAL_TEMP, FINAL_EXPENSES_BURIAL
                );
                state = setDollarInput(
                    state, FINAL_EXPENSES_FUNERAL_TEMP, FINAL_EXPENSES_FUNERAL
                );

                return state;
            }
        ),
        saveFinalExpenses: () => update(
            state => {
                state[FINAL_EXPENSES_EDIT] = false;

                state = setDollarInput(
                    state, FINAL_EXPENSES_BURIAL, FINAL_EXPENSES_BURIAL_TEMP
                );
                state = setDollarInput(
                    state, FINAL_EXPENSES_FUNERAL, FINAL_EXPENSES_FUNERAL_TEMP
                );

                state = calculator.updateCalcResultsSlices(state);
                return state;
            }
        ),

        downloadCalcPdf: state => {
            return fetchId(state)
                .then(id => {
                    state.hasError = false;
                    state.errorMsg = '';
                    window.open(
                        `/life-calculator/api/v1/calc/pdf/${id}`,
                        `pfg-calc-${id}`
                    );
                })
                .catch(() => {
                    state.hasError = true;
                    state.errorMsg = 'Unable to get pdf. Please retry.';
                });
        },
        getSharableCalcURL: state => {
            return fetchId(state)
                .then(id => {
                    state.hasError = false;
                    state.errorMsg = '';
                    return (
                        `${window.location.origin}/life-calculator/calculator/my/results/${id}`
                    );
                })
                .catch(err => {
                    console.log(err);
                    state.hasError = true;
                    state.errorMsg = 'Unable to copy link. Please retry.';
                    throw new Error(err);
                });
        },

        ...PieChart.State.Actions(update)
    })
});


const formatDollars = amount => `$${shared.formatNumber(amount)}`;

const ChartSection = ({ state, actions }) => {
    const [copyLinkText, setCopyLinkText] = useState('Copy Link');
    const [pdfText, setPdfText] = useState('Get PDF');
    const history = useHistory();

    useEffect(() => {
        return () => {
            state.hasError = false;
        };
    });

    return (
        <div className={styles.chartSection}>
            <div className={styles.editAnchor}>
                <a className={styles.controls}
                    href="#edit-section"
                >
                    <span>Edit</span>
                    <img src={EditIcon} alt="Edit this information" />
                </a>
            </div>
            <div className={styles.chartTitle}>
                <h1 className={styles.sectionTitle}>
                    Your estimated coverage need
                </h1>
            </div>
            <div className={styles.chart}>
                <div className={styles.pie}>
                    <SvgPie.Chart id={CALC_RESULTS_PIE}
                        slices={state[CALC_RESULTS_PIE].slices}
                        size={250}
                    />
                </div>
                <div>
                    <PieChart.KeyComponent id={CALC_RESULTS_PIE}
                        state={state}
                        actions={actions}
                        formatter={formatDollars}
                    />
                </div>
            </div>
            <h2 className={styles.nextStepsTitle}>Ready to take the next step?</h2>
            <div className={styles.nextSteps}>
                <div className={styles.nextStepsColumn}>
                    <div className={styles.nextStepsLabel}>
                        Review with someone you trust
                    </div>
                    <div>
                        <ErrorMessage errorMsg={state.errorMsg} hasError={state.hasError} />
                    </div>
                    <div className={styles.nextStepsActions}>
                        <button type="button"
                            name="getPDF"
                            id="view-PDF"
                            className={`pds-button pds-button-ghost ${styles.pdfButton}`}
                            data-gtm="call-to-action"
                            onClick={() => {
                                setPdfText('Getting PDF...');
                                actions.downloadCalcPdf(state)
                                    .then(() => setPdfText('Get PDF'));
                                wa.trackEvent('formSubmit', {
                                    type: 'contact-prospect',
                                    name: 'getPDF',
                                    id: 'view-PDF',
                                    lead: 'no'
                                });
                            }}
                        >
                            {pdfText}
                        </button>
                        <button type="button"
                            name="copy-link"
                            id="copyLink2"
                            className={`pds-button pds-button-ghost ${styles.linkButton}`}
                            data-gtm="call-to-action"
                            onClick={() => {
                                setCopyLinkText('Getting Link...');
                                actions.getSharableCalcURL(state)
                                    .then(url => {
                                        copy(url);
                                        setCopyLinkText('Link Copied');
                                    })
                                    .catch(() => {
                                        setCopyLinkText('Copy Link');
                                    });
                                wa.trackEvent('modalClick', {
                                    clicktext: 'Getting Link...',
                                    modaltitle: 'Copy Link'
                                });
                            }}
                        >
                            {copyLinkText}
                        </button>
                    </div>
                </div>
                <div className={styles.nextStepsColumn}>
                    <div className={styles.nextStepsLabel}>
                        Find out how much this would cost
                    </div>
                    <div className={styles.nextStepsActions}>
                        <button type="button"
                            data-gtm="call-to-action"
                            className={`pds-button pds-button-primary ${styles.getQuoteButton}`}
                            onClick={
                                evt => {
                                    history.push('/life-calculator/quote/about');
                                    actions.resetNav();
                                    evt.preventDefault();
                                    wa.trackEvent('pageNavigationClick', {
                                        clicktext: state[QUOTE_TERM_RATES]
                                            ? 'Return to quote' : 'Get a quote',
                                        navigationsection: 'call to action'
                                    });
                                }
                            }
                        >
                            {state[QUOTE_TERM_RATES]
                                ? 'Return to quote' : 'Get a quote'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

ChartSection.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.shape(
        { [CALC_RESULTS_PIE]: PieChart.statePropTypes.isRequired }
    ).isRequired
};


const TextRow = ({ label }) => (
    <div className={styles.row}>
        <div className={styles.label}>{label}</div>
    </div>
);

TextRow.propTypes = { label: PropTypes.string.isRequired };


const FormRow = ({ label, input }) => (
    <div className={styles.row}>
        <div className={styles.label}>{label}</div>
        <div className={styles.input}>{input}</div>
    </div>
);

FormRow.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.node.isRequired
};


const DollarsRow = ({
    id,
    tempId,
    label,
    editing,
    state,
    actions,
    sourceValueGetter
}) => (
    <FormRow label={label}
        input={
            editing
                ? (
                    <div className={styles.dollarsEditRow}>
                        <DollarInput.Component id={tempId}
                            state={state}
                            actions={actions}
                            hideErrors
                        />
                    </div>
                )
                : (
                    <div className={styles.dollarsRow}>
                        $
                        {shared.formatNumber(sourceValueGetter(state[id]) || 0)}
                    </div>
                )
        }
    />
);

DollarsRow.defaultProps = { sourceValueGetter: DollarInput.State.get };

DollarsRow.propTypes = {
    id: PropTypes.string.isRequired,
    tempId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    editing: PropTypes.bool.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        PropTypes.oneOfType([
            DollarInput.statePropTypes,
            Slider.statePropTypes
        ]).isRequired
    ),
    actions: DollarInput.actionPropTypes.isRequired,
    sourceValueGetter: PropTypes.func
};


const IntegerMultiplierRow = ({
    id,
    tempId,
    label,
    editing,
    state,
    actions
}) => (
    <FormRow label={label}
        input={
            editing
                ? (
                    <div className={styles.multiplierEditRow}>
                        <div className={styles.multiplier}>X</div>
                        <NumberInput.Component id={tempId}
                            state={state}
                            actions={actions}
                            hideErrors
                        />
                    </div>
                )
                : (
                    <div className={styles.multiplierRow}>
                        <div className={styles.multiplier}>X</div>
                        <div>
                            {shared.formatNumber(
                                NumberInput.State.get(state[id]) || 0
                            )}
                        </div>
                    </div>
                )
        }
    />
);

IntegerMultiplierRow.propTypes = {
    id: PropTypes.string.isRequired,
    tempId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    editing: PropTypes.bool.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        NumberInput.statePropTypes.isRequired
    ),
    actions: NumberInput.actionPropTypes.isRequired
};


const PercentageMultiplierRow = ({
    id,
    tempId,
    label,
    editing,
    state,
    actions
}) => (
    <FormRow label={label}
        input={
            editing
                ? (
                    <div className={styles.percentageEditRow}>
                        <div className={styles.multiplier}>X</div>
                        <div className={styles.percentageInput}>
                            <NumberInput.Component id={tempId}
                                state={state}
                                actions={actions}
                                hideErrors
                            />
                            <div className={styles.percentage}>%</div>
                        </div>
                    </div>
                )
                : (
                    <div className={styles.percentageRow}>
                        <div className={styles.multiplier}>X</div>
                        <div>
                            {shared.formatNumber(
                                NumberInput.State.get(state[id]) || 0
                            )}
                        </div>
                        <div className={styles.percentage}>%</div>
                    </div>
                )
        }
    />
);

PercentageMultiplierRow.propTypes = {
    id: PropTypes.string.isRequired,
    tempId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    editing: PropTypes.bool.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        NumberInput.statePropTypes.isRequired
    ),
    actions: NumberInput.actionPropTypes.isRequired
};

const FormEditControls = ({ editId, editing, onEdit, onCancel }) => {
    if (!editing) {
        return (
            <div className={styles.controls}
                onClick={() => onEdit()}
                onKeyPress={() => onEdit()}
                role="button"
                tabIndex="0"
            >
                <span>Edit</span>
                <img src={EditIcon} alt="Edit this information" />
            </div>
        );
    }

    return (
        <div className={styles.controls}
            onClick={() => onCancel(editId)}
            onKeyPress={() => onCancel(editId)}
            role="button"
            tabIndex="0"
        >
            <span>Cancel</span>
            <img src={CancelEditIcon} alt="Cancel editing without saving" />
        </div>
    );
};

FormEditControls.propTypes = {
    editId: PropTypes.string.isRequired,
    editing: PropTypes.bool.isRequired,
    onEdit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};


const Form = ({
    editId,
    title,
    rows,
    totaler,
    tempTotaler,
    onEdit,
    onSave,
    state,
    actions
}) => (
    <div className={styles.form}>
        <div className={styles.titleRow}>
            <div className={styles.title}>{title}</div>
            <FormEditControls editId={editId}
                editing={state[editId]}
                onEdit={onEdit}
                onCancel={actions.cancelEditForm}
            />
        </div>
        {
            rows.map(({ Node, id, tempId, label, sourceValueGetter }) => (
                <Node key={id}
                    id={id}
                    tempId={tempId}
                    label={label}
                    editing={state[editId]}
                    state={state}
                    actions={actions}
                    sourceValueGetter={sourceValueGetter}
                />
            ))
        }
        <div className={styles.spacerRow} />
        <div className={styles.totalRow}>
            <div className={styles.totalLabel}>Total</div>
            <div className={styles.totalAmount}>
                $
                {shared.formatNumber(
                    state[editId] ? tempTotaler(state) : totaler(state)
                )}
            </div>
        </div>
        {!state[editId]
            ? null
            : (
                <div className={styles.nextButton}>
                    <button type="submit"
                        className="pds-button pds-button-ghost"
                        onClick={() => onSave()}
                    >
                        Save changes
                    </button>
                </div>
            )
        }
    </div>
);

Form.propTypes = {
    editId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({
        Node: PropTypes.func.isRequired,
        id: PropTypes.string.isRequired,
        tempId: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        sourceValueGetter: PropTypes.func // eslint-disable-line react/require-default-props, max-len
    })).isRequired,
    onEdit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    totaler: PropTypes.func.isRequired,
    tempTotaler: PropTypes.func.isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};


/* Replace Income Edit Form */
const getReplaceIncomeTempTotal = state => shared.computeReplaceIncome(
    DollarInput.State.get(state[REPLACE_INCOME_AMOUNT_TEMP]),
    NumberInput.State.get(state[REPLACE_INCOME_GROWTH_TEMP]),
    NumberInput.State.get(state[REPLACE_INCOME_YEARS_TEMP])
);

const ReplaceIncomeForm = ({ state, actions }) => (
    <Form title="Replace income"
        editId={REPLACE_INCOME_EDIT}
        onEdit={actions.editReplaceIncome}
        onSave={actions.saveReplaceIncome}
        state={state}
        actions={actions}
        rows={[
            {
                Node: DollarsRow,
                id: REPLACE_INCOME_AMOUNT,
                tempId: REPLACE_INCOME_AMOUNT_TEMP,
                label: 'Annual income'
            },
            {
                Node: IntegerMultiplierRow,
                id: REPLACE_INCOME_YEARS,
                tempId: REPLACE_INCOME_YEARS_TEMP,
                label: 'Replacement years'
            },
            {
                Node: PercentageMultiplierRow,
                id: REPLACE_INCOME_GROWTH_RATE,
                tempId: REPLACE_INCOME_GROWTH_TEMP,
                label: 'Income growth rate'
            }
        ]}
        totaler={calculator.computeReplaceIncomeFromState}
        tempTotaler={getReplaceIncomeTempTotal}
    />
);

ReplaceIncomeForm.propTypes = {
    state: PropTypes.shape({
        [REPLACE_INCOME_AMOUNT]: DollarInput.statePropTypes.isRequired,
        [REPLACE_INCOME_AMOUNT_TEMP]: DollarInput.statePropTypes.isRequired,
        [REPLACE_INCOME_YEARS]: NumberInput.statePropTypes.isRequired,
        [REPLACE_INCOME_YEARS_TEMP]: NumberInput.statePropTypes.isRequired,
        [REPLACE_INCOME_GROWTH_RATE]: NumberInput.statePropTypes.isRequired,
        [REPLACE_INCOME_GROWTH_TEMP]: NumberInput.statePropTypes.isRequired
    }).isRequired,
    actions: PropTypes.shape({
        editReplaceIncome: PropTypes.func.isRequired,
        saveReplaceIncome: PropTypes.func.isRequired
    }).isRequired
};

/* Cover Debt Form */
const getCoverDebtTemp = state => shared.computeCoverDebt(
    DollarInput.State.get(state[COVER_MORTGAGE_TEMP]),
    DollarInput.State.get(state[COVER_STUDENT_LOAN_TEMP]),
    DollarInput.State.get(state[COVER_OTHER_TEMP])
);

const CoverDebtForm = ({ state, actions }) => (
    <Form title="Cover debt"
        editId={COVER_DEBT_EDIT}
        state={state}
        onEdit={actions.editCoverDebt}
        onSave={actions.saveCoverDebt}
        actions={actions}
        rows={[
            {
                Node: DollarsRow,
                id: COVER_MORTGAGE_KEY,
                tempId: COVER_MORTGAGE_TEMP,
                label: 'Mortgage'
            },
            {
                Node: DollarsRow,
                id: COVER_STUDENT_LOAN_KEY,
                tempId: COVER_STUDENT_LOAN_TEMP,
                label: 'Student loans'
            },
            {
                Node: DollarsRow,
                id: COVER_OTHER_KEY,
                tempId: COVER_OTHER_TEMP,
                label: 'Other'
            }
        ]}
        totaler={calculator.computeCoverDebtFromState}
        tempTotaler={getCoverDebtTemp}
    />
);

CoverDebtForm.propTypes = {
    state: PropTypes.shape({
        COVER_MORTGAGE_KEY: DollarInput.statePropTypes,
        COVER_MORTGAGE_TEMP: DollarInput.statePropTypes,
        COVER_STUDENT_LOAN_KEY: DollarInput.statePropTypes,
        COVER_STUDENT_LOAN_TEMP: DollarInput.statePropTypes,
        COVER_OTHER_KEY: DollarInput.statePropTypes,
        COVER_OTHER_TEMP: DollarInput.statePropTypes
    }).isRequired,
    actions: PropTypes.shape({
        editCoverDebt: PropTypes.func.isRequired,
        saveCoverDebt: PropTypes.func.isRequired
    }).isRequired
};

/* Fund Education Form */
const getFundEducationTemp = state => shared.computeFundEducation(
    NumberInput.State.get(state[FUND_NUMBER_TEMP]),
    NumberInput.State.get(state[FUND_YEARS_TEMP]),
    DollarInput.State.get(state[FUND_COST_TEMP])
);

const FundEducationForm = ({ state, actions }) => (
    <Form title="Fund education"
        editId={FUND_EDUCATION_EDIT}
        onEdit={actions.editFundEducation}
        onSave={actions.saveFundEducation}
        state={state}
        actions={actions}
        rows={[
            {
                Node: TextRow,
                id: FUND_TYPE_KEY,
                tempId: FUND_TYPE_KEY,
                label: shared.getFundTypeName(state[FUND_TYPE_KEY])
            },
            {
                Node: DollarsRow,
                id: FUND_TYPE_COST_KEY,
                tempId: FUND_COST_TEMP,
                label: 'Annual cost',
                sourceValueGetter: Slider.State.getValue
            },
            {
                Node: IntegerMultiplierRow,
                id: FUND_TYPE_YEARS,
                tempId: FUND_YEARS_TEMP,
                label: 'Years'
            },
            {
                Node: IntegerMultiplierRow,
                id: FUND_NUMBER_KEY,
                tempId: FUND_NUMBER_TEMP,
                label: 'Attendees'
            }
        ]}
        totaler={calculator.computeFundEducationFromState}
        tempTotaler={getFundEducationTemp}
    />
);

FundEducationForm.propTypes = {
    state: PropTypes.shape({
        FUND_TYPE_COST_KEY: Slider.statePropTypes,
        FUND_COST_TEMP: DollarInput.statePropTypes,
        FUND_TYPE_YEARS: NumberInput.statePropTypes,
        FUND_YEARS_TEMP: NumberInput.statePropTypes,
        FUND_NUMBER_KEY: NumberInput.statePropTypes,
        FUND_NUMBER_TEMP: NumberInput.statePropTypes
    }).isRequired,
    actions: PropTypes.shape({
        editFundEducation: PropTypes.func.isRequired,
        saveFundEducation: PropTypes.func.isRequired
    }).isRequired
};

/* Final Expenses Edit Form */
const getFinalExpensesTemp = state => shared.computeFinalExpenses(
    DollarInput.State.get(state[FINAL_EXPENSES_BURIAL_TEMP]),
    DollarInput.State.get(state[FINAL_EXPENSES_FUNERAL_TEMP])
);

const FinalExpensesForm = ({ state, actions }) => (
    <Form title="Final expenses"
        editId={FINAL_EXPENSES_EDIT}
        onEdit={actions.editFinalExpenses}
        onSave={actions.saveFinalExpenses}
        state={state}
        actions={actions}
        rows={[
            {
                Node: DollarsRow,
                id: FINAL_EXPENSES_BURIAL,
                tempId: FINAL_EXPENSES_BURIAL_TEMP,
                label: 'Burial or cremation'
            },
            {
                Node: DollarsRow,
                id: FINAL_EXPENSES_FUNERAL,
                tempId: FINAL_EXPENSES_FUNERAL_TEMP,
                label: 'Funeral'
            }
        ]}
        totaler={calculator.computeFinalExpensesFromState}
        tempTotaler={getFinalExpensesTemp}
    />
);

FinalExpensesForm.propTypes = {
    state: PropTypes.shape({
        FINAL_EXPENSES_BURIAL: DollarInput.statePropTypes,
        FINAL_EXPENSES_BURIAL_TEMP: DollarInput.statePropTypes,
        FINAL_EXPENSES_FUNERAL: DollarInput.statePropTypes,
        FINAL_EXPENSES_FUNERAL_TEMP: DollarInput.statePropTypes
    }).isRequired,
    actions: PropTypes.shape({
        editFinalExpenses: PropTypes.func.isRequired,
        saveFinalExpenses: PropTypes.func.isRequired
    }).isRequired
};

/* General Edit Form Layout */
const FormsSection = ({ state, actions }) => (
    <div className={styles.formsSection} id="edit-section">
        <h1 className={styles.howTitle}>
            How we arrived at this total
        </h1>
        <div className={styles.forms}>
            <ReplaceIncomeForm state={state} actions={actions} />
            <CoverDebtForm state={state} actions={actions} />
            <FundEducationForm state={state} actions={actions} />
            <FinalExpensesForm state={state} actions={actions} />
        </div>
    </div>
);

FormsSection.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};

const CalcResultsComponent = ({ state, actions }) => {
    if (!state[INFO_PAGES_COMPLETE]) {
        return <Redirect to="/life-calculator/calculator/info" />;
    }

    return (
        <main className={styles.resultsContainer}>
            <ChartSection state={state} actions={actions} />
            <FormsSection state={state} actions={actions} />
        </main>
    );
};

CalcResultsComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.objectOf(PropTypes.any).isRequired
};

export default {
    State: CalcResultsState,
    Component: CalcResultsComponent
};
