@value pdsBlack, pdsWhite, pdsMediumDarkGray, pdsMediumLightGray, pdsDarkGray, principalBlue, principalMidnight, boldFont from './common.module.css';
@value smallScreen, largeScreen, bigScreen, hugeScreen from './common.module.css';


.quoteResult {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: stretch;

    width: 385px;
}


.cardItem {
    border-radius: 3px;
    border: 1px solid pdsMediumLightGray;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    margin-bottom: 30px;
    padding: 15px;
    text-align: center;

    width: 385px;
}

.cardTitle {
    font-size: 24px;
    font-family: boldFont;
    color: pdsDarkGray;

    margin-bottom: 20px;
    margin-top: 2px;
    text-align: center;
}

.shareOrSave {
    border-radius: 3px;
    border: 1px solid pdsMediumLightGray;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;

    margin-bottom: 0px;
    padding-bottom: 30px;
    text-align: center;

    width: 100%;
}

.shareOrSaveTitle {
    font-size: 20px;
    font-family: boldFont;
    color: pdsDarkGray;

    margin-bottom: 20px;
    text-align: center;
}

.shareOrSaveButtons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.pdfButton::before {
    font-family: "FontAwesome";
    content: "\f1c1";
    margin-right: 5px;
}

.linkButton::before {
    font-family: "FontAwesome";
    content: "\f0c1";
    margin-right: 5px;
}

@media all and (max-width: largeScreen) {
    .shareOrSaveButtons {
        flex-direction: column;
        align-items: center;
    }

    .pdfButton {
        margin-bottom: 10px;
    }
}

@media all and (min-width: largeScreen) {
    .shareOrSaveButtons {
        justify-content: center;
    }

    .pdfButton {
        margin-right: 15px;
    }
}

@media all and (max-width: smallScreen) {
    .quoteResult {
        width: calc(100% - 30px);

        margin: 15px;
    }

    .cardItem {
        width: 100%;
    }

    .cardTitle {
        font-size: 17px;
    }

}

@media all and (min-width: bigScreen) {
    .quoteResult {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        max-width: none;
        width: 960px;
    }

    .cardItem {
        width: 450px;

        padding: 25px 55px;
    }

    .cardTitle {
        font-size: 24px;
    }
}

.cardSubtitle {
    text-align: center;
    color: pdsDarkGray;
    margin-top: 0;
    padding-bottom: 25px;

    font-size: 18px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.amountCallToAction {
    margin-top: 22px;

    text-align: left;
    padding: 25px 10px 10px 10px;

    display: flex;
    flex-direction: row;

    border-top: 1px solid pdsMediumLightGray;
}

.amountCallToAction a::after {
    font-family: "FontAwesome";
    content: "\f105";
    margin-left: 5px;
}


.pie {
    margin-right: 10px;
    padding: 5px;
}

.calc {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
}


.calc p {
    margin: 0;
    font-size: 16px;
}

.calc a {
    margin: 0;
    font-size: 14px;
}

.quotes {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
}

@media screen and (max-width: smallScreen) {
    .quotes {
        flex-direction: column;
    }

    .premiumQuote {
        border-right: 0 !important;
        border-bottom: 1px solid pdsMediumLightGray;
        margin-left: 10px;
        margin-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .premiumQuote:last-child {
        border-bottom: 0;
    }
}

.premiumQuote {
    flex: 1;
    color: pdsMediumDarkGray;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.premiumQuote:not(:last-child) {
    border-right: 1px solid pdsMediumLightGray;
}

.premiumQuote > p {
    padding: 0;
    margin: 0;
    color: pdsMediumDarkGray;
}

p.premiumLabel {
    padding-top: 15px;
}

p.premiumAmount > span {
    color: pdsBlack;
    font-family: boldFont;
    font-size: 24px;
}

.howToChooseTerm::before {
    font-family: "FontAwesome";
    content: "\f073";
    margin-right: 4px;
}

.howToChooseTerm {
    background: transparent;
    border: none;
    color: principalBlue;
    font-size: 18px;
    font-family: boldFont;
    margin-bottom: 10px;
    margin-top: 15px;
}

.howToChooseTerm:hover {
    cursor: pointer;
    text-decoration: underline;
}

.howToChooseTerm::after {
    font-family: "FontAwesome";
    content: "\f105";
    margin-left: 5px;
}


/* Next Steps Stuff */

.nextSteps {
    margin: 0px 0px 30px 0px;
    padding: 15px;
    height: 250px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.nextSteps .cardTitle {
    width: 100%;
}

.nextStepReview,
.nextStepLearn,
.nextStepCTA {
    width: 100%;

    padding: 30px 0px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.nextStepReview {
    padding: 0px 0px 30px 0px;
}

.nextStepLearn,
.nextStepCTA {
    border-top: 1px solid pdsMediumLightGray;
}

.nextStepReview > img {
    margin: 15px 0px;
    width: 191px;
    height: 191px;
    object-fit: cover;
    border-radius: 50%;
}

.nextStepLearn > img {
    margin: 15px 0px 40px;
    width: 129px;
    height: 187px;
}

.nextStepTitle {
    font-size: 18px;

    width: 100%;

    color: principalMidnight;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.nextStepReview .nextStepTitle {
    font-size: 20px;
}

.nextStepNumber {
    display: flex;
    justify-content: center;

    font-size: 24px;
    color: pdsWhite;
    font-weight: normal;

    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: principalMidnight;
}

.nextStepTitleLabel {
    padding-left: 15px;
    text-align: left;
    flex: 1;
}

@media all and (min-width: bigScreen) {
    .nextSteps {
        width: 100%;
        padding: 40px;
    }

    .nextStepReview,
    .nextStepLearn {
        width: 438px;
        padding: 0px;
    }

    .nextStepLearn {
        padding-left: 40px;
        border-left: 1px solid pdsMediumLightGray;
        border-top: none;
    }

    .nextStepCTA {
        border-top: none;
        margin-top: 50px;
        padding: 0px;
    }

    .nextStepTitle {
        font-size: 22px;
    }

    .nextStepNumber {
        align-self: flex-start;
    }
}

@media all and (max-width: smallScreen) {
    .nextStepNumber {
        align-self: flex-start;
    }
}


.nextStepsActions {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nextStepsActions button {
    margin: 5px 0px;
}

.reviewCTA {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.reviewCTA p {
    margin: 0 0 10px 0;
    color: pdsDarkGray;
}
