import React from 'react';
import PropTypes from 'prop-types';

import UnsureCallToAction from '../calc-unsure-cta';

import styles from '../../css/quote-result.module.css';

const NextSteps = ({ state, actions }) => (
    <div className={[styles.cardItem, styles.nextSteps].join(' ')}>
        <h2 className={styles.cardTitle}>Ready to take the next step?</h2>
        <UnsureCallToAction.Component compact state={state} actions={actions} />
    </div>
);

NextSteps.propTypes = {
    actions: PropTypes.shape().isRequired,
    state: PropTypes.object.isRequired // eslint-disable-line react/forbid-prop-types, max-len
};

export default NextSteps;
