import React from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router';
import { useHistory, useRouteMatch } from 'react-router-dom';
import stateChecker from '../../state-checker';

import styles from '../../css/page-progress.module.css';

const newPage = (pageKey, pageLabel, isEnabled, pagePath) => ({
    key: pageKey,
    label: pageLabel,
    enabled: isEnabled,
    path: pagePath
});

const addPage = (state, pageKey, pageLabel, isEnabled, pagePath) => {
    let enabled = isEnabled;

    // If this is the first page ensure it is marked as enabled.
    if (state.pages.length === 0) {
        enabled = true;
    }

    state.pages.push(newPage(pageKey, pageLabel, enabled, pagePath));

    return state;
};

const addPages = (state, pages) => (
    pages.reduce(
        (newState, page) => addPage(newState, ...page),
        state
    )
);

const setPages = (state, pages) => {
    state.pages = [];
    return addPages(state, pages);
};

const setPage = (state, key, history) => {
    const page = state.pages.find(
        (candidate) => candidate.key === key
    );

    if (page) {
        page.enabled = true;

        if (history) {
            history.push(`${state.rootPath}/${page.path}`);
        }
    }

    return state;
};

const disableSubsequent = (state, disableAfterKey) => {
    const pageIndex = state.pages.findIndex(
        candidate => candidate.key === disableAfterKey
    );

    state.pages = state.pages.map(
        (page, index) => {
            if (index > pageIndex) {
                page.enabled = false;
            }
            return page;
        }
    );

    return state;
};

const renderPage = (state, location, pageMap, defaultPageKey) => {
    const defaultPageComponent = (defaultPageKey && pageMap[defaultPageKey])
        ? pageMap[defaultPageKey]
        : () => null;

    const page = state.pages.find(
        candidate => matchPath(
            location.pathname,
            {
                path: `${state.rootPath}/${candidate.path}`,
                strict: true,
                exact: true
            }

        )
    );

    if (!page) { return defaultPageComponent(); }

    const child = pageMap[page.key];

    if (!child) { return defaultPageComponent(); }

    return child();
};

const getPageUrlByKey = (state, pageKey) => {
    const page = state.pages.find(
        candidate => candidate.key === pageKey
    );

    if (!page) { throw new Error(`Page, ${pageKey}, was not found`); }

    return `${state.rootPath}/${page.path}`;
};

const getPageByIndex = (state, pageIndex) => {
    const page = state.pages[pageIndex];

    if (!page) { throw new Error(`Page, ${pageIndex}, was not found`); }

    return `${state.rootPath}/${page.path}`;
};

const navigateToFirst = (state, history) => {
    history.push(getPageByIndex(state, 0));
};

const PageProgressState = ({
    Initial: (rootPath, defaultPages) => {
        const initial = {
            pages: [],
            rootPath: rootPath !== undefined ? rootPath : '/'
        };

        if (defaultPages) {
            initial.pages = defaultPages;
        }

        return initial;
    },
    addPage,
    addPages,
    setPages,
    disableSubsequent,
    getPageUrlByKey,
    navigateToFirst,
    removePage: (state, pageKey) => {
        // TODO: If the page being removed is selected, select the prior page.
        // TODO: Ensure the 0th page is enabled.
        state.pages = state.pages.filter(page => page.key !== pageKey);

        return state;
    },
    setPage,
    getCurrent: (state, location) => {
        const page = state.pages.find(
            candidate => matchPath(
                location.pathname,
                {
                    path: `${state.rootPath}/${candidate.path}`,
                    strict: true,
                    exact: true
                }

            )
        );

        if (page) {
            return page.key;
        }

        return null;
    },
    getNext: (state, location) => {
        const pageIndex = state.pages.findIndex(
            candidate => matchPath(
                location.pathname,
                {
                    path: `${state.rootPath}/${candidate.path}`,
                    strict: true,
                    exact: true
                }

            )
        );

        if (pageIndex < 0) {
            // This is an exception case.
            return null;
        }

        if ((pageIndex + 1) >= state.pages.length) {
            // This is already the last page.
            return null;
        }

        return state.pages[pageIndex + 1].key;
    },
    renderPage,
    Actions: update => (
        {
            setPage: (id, targetPageKey, history) => {
                update({
                    [id]: state => setPage(state, targetPageKey, history)
                });
            },
            nextPage: (id, history, location) => {
                update({
                    [id]: state => {
                        const activeIndex = state.pages.findIndex(
                            candidate => matchPath(
                                location.pathname,
                                {
                                    path: `${state.rootPath}/${candidate.path}`,
                                    strict: true,
                                    exact: true
                                }

                            )
                        );

                        const nextIndex = activeIndex + 1;
                        if (activeIndex >= 0 && nextIndex < state.pages.length) {
                            const nextPage = state.pages[nextIndex];

                            nextPage.enabled = true;

                            history.push(`${state.rootPath}/${nextPage.path}`);
                        }

                        return state;
                    }
                });
            }
        }
    )
});


const calcClasses = (classNames, enabled, isCurrentPage, index) => {
    if (isCurrentPage) {
        classNames.push(styles.current);
    }

    if (enabled) {
        classNames.push(styles.enabled);
    } else {
        classNames.push(styles.disabled);
    }

    if (index === 0) {
        classNames.push(styles.first);
    }

    return classNames.join(' ');
};


const MeterPart = ({ id, rootPath, actions, index, page }) => {
    const { key, enabled, label, path } = page;
    const history = useHistory();

    const isCurrentPage = useRouteMatch(`${rootPath}/${path}`);

    const classNames = calcClasses([styles.meterSegment],
        enabled, isCurrentPage, index);

    return (
        <div className={classNames}>
            <div className={styles.meterMarker}
                role="button"
                aria-disabled={!enabled}
                aria-label={`visit ${label} page`}
                tabIndex="0"
                onKeyDown={!enabled ? null
                    : evt => {
                        if (evt.keyCode === 13) {
                            actions.setPage(id, key, history);
                        }
                    }
                }
                onMouseDown={evt => evt.preventDefault()}
                onClick={!enabled ? null : () => {
                    actions.setPage(id, key, history);
                }}
            />
            <div className={styles.meterText}
                role="button"
                aria-disabled={!enabled}
                aria-hidden="true"
                tabIndex="-1"
                onKeyDown={!enabled ? null
                    : evt => {
                        if (evt.keyCode === 13) {
                            actions.setPage(id, key, history);
                        }
                    }
                }
                onMouseDown={evt => evt.preventDefault()}
                onClick={!enabled ? null : () => {
                    actions.setPage(id, key, history);
                }}
            >
                <p className="pds-typography-small" aria-hidden="true">
                    {label}
                </p>
            </div>
        </div>
    );
};

MeterPart.propTypes = {
    id: PropTypes.string.isRequired,
    rootPath: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    actions: PropTypes.shape({ setPage: PropTypes.func.isRequired }).isRequired,
    page: PropTypes.shape(
        {
            key: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            enabled: PropTypes.bool.isRequired
        }
    ).isRequired
};


const makeElements = (id, rootPath, actions) => {
    return (acc, page, index) => {
        const [parts] = acc;
        parts.push(<MeterPart id={id}
            actions={actions}
            rootPath={rootPath}
            index={index}
            page={page}
            key={`${id}-${page.key}`}
        />);

        return acc;
    };
};

const PageProgressComponent = ({ id, state, actions, className }) => {
    const [meters] = state[id].pages.reduce(
        makeElements(id, state[id].rootPath, actions),
        [[], []]
    );

    const children = [];

    meters.forEach((meter, meterIndex) => {
        children.push(meter);

        const nextMeter = meters[meterIndex + 1];

        if (nextMeter) {
            const { enabled } = nextMeter.props.page;
            const key = meterIndex.toString();

            const classNames = calcClasses([styles.meterSpacer],
                enabled, null, null);

            children.push(<div key={key} className={classNames} />);
        }
    });

    return (
        <nav className={[styles.pageProgress, className].join(' ')} aria-label="page navigation">
            {children}
        </nav>
    );
};

PageProgressComponent.defaultProps = { className: null };

PageProgressComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: PropTypes.shape({ setPage: PropTypes.func.isRequired }).isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        PropTypes.shape({
            pages: PropTypes.arrayOf(
                PropTypes.shape({
                    key: PropTypes.string.isRequired,
                    label: PropTypes.string.isRequired,
                    enabled: PropTypes.bool.isRequired,
                    path: PropTypes.string.isRequired
                })
            ),
            rootPath: PropTypes.string
        }).isRequired
    ),
    className: PropTypes.string
};


export default {
    State: PageProgressState,
    Component: PageProgressComponent,
    newPage
};
