import { DateTime } from 'luxon';
import shared from '@principal/life-calculator-helpers';
import { v4 as uuidv4 } from 'uuid';
import DateEntry from '../date-entry';
import Gender from '../gender';
import ZipCode from '../zip';

import AppointmentEntry from '../appointment-input';
import NameEntry from '../name-input';
import PhoneEntry from '../phone-input';
import EmailEntry from '../email-input';
import MultipleEntry from '../multiple-input';
import DollarInput from '../dollar-input';
import NumberInput from '../number-input';
import Slider from '../slider';

import convertDate, {
    CONTACT_HOUR_OPTIONS
} from '../../constants';


const callBackRfc = (callbackDate, callbackTime) => {
    callbackDate = convertDate(callbackDate);
    const time = DateTime.fromISO(callbackDate, { hour: CONTACT_HOUR_OPTIONS[callbackTime].hour });
    return time.toUTC().toString();
};

const premium = (state, faceValue, term) => {
    if (state[shared.QUOTE_TERM_RATES]) {
        const rate = parseFloat(state[shared.QUOTE_TERM_RATES][shared.buildTermKey(faceValue, term)]);
        const scaledFaceValue = (faceValue / 1000) * parseFloat(rate, term);
        return Math.round((scaledFaceValue + shared.SERVICE_FEE) * shared.EFT_MULTIPLE);
    }
    return null;
};

const formatNameInput = s => {
    if (typeof s !== 'string') return '';
    let nameString = s.charAt(0).toUpperCase() + s.slice(1);
    nameString = nameString.replace(/`/g, '\'');
    return nameString;
};

const formatPhoneInput = phoneInput => phoneInput.replace(/\(|-|\s|\)/g, '');

const contactValues = (state, advisor) => {
    const mode = advisor ? 'meet-an-advisor' : 'schedule-a-call';
    const rfcTime = callBackRfc(
        AppointmentEntry.State.getAppointment(state[shared.CONTACT_APPOINTMENT]),
        MultipleEntry.State.get(state[shared.CONTACT_HOUR_KEY])
    );
    const faceAmount = Slider.State.getValue(state[shared.QUOTE_FACE_VALUE]);
    return {
        case: {
            action: mode,
            description: `### Contact ###
                First Name: ${NameEntry.State.getName(state[shared.CONTACT_NAME].first)}
                Last Name: ${NameEntry.State.getName(state[shared.CONTACT_NAME].last)}
                Birth Date: ${DateEntry.State.isValid(state[shared.ABOUT_DOB]) ? DateEntry.State.getDate(state[shared.ABOUT_DOB]) : null}
                Gender: ${Gender.State.isValid(state[shared.ABOUT_GENDER]) ? Gender.State.getGender(state[shared.ABOUT_GENDER]) : 'Unknown'}
                Phone Number: ${formatPhoneInput(PhoneEntry.State.getPhone(state[shared.CONTACT_PHONE]))}
                Email Address: ${EmailEntry.State.getEmail(state[shared.CONTACT_EMAIL])}
                State: ${MultipleEntry.State.get(state[shared.ABOUT_STATE])}
                Zip Code: ${ZipCode.State.getZip(state[shared.CONTACT_ZIP_CODE])}
                #### Best Time to Call ####
                Contact Date: ${AppointmentEntry.State.getAppointment(state[shared.CONTACT_APPOINTMENT])}
                Contact Time: ${MultipleEntry.State.get(state[shared.CONTACT_HOUR_KEY])}
                ### Life Insurance Coverage Options ###
                Face Amount: ${faceAmount}
                10 Year Term Premium: ${premium(state, faceAmount, 10)}
                20 Year Term Premium: ${premium(state, faceAmount, 20)}
                30 Year Term Premium: ${premium(state, faceAmount, 30)}
                ### Personal Data ###
                ### Income ###
                Income: ${DollarInput.State.get(state[shared.REPLACE_INCOME_AMOUNT])}
                Years to Replace: ${NumberInput.State.get(state[shared.REPLACE_INCOME_YEARS])}
                Income Growth: ${NumberInput.State.get(state[shared.REPLACE_INCOME_GROWTH_RATE])}
                ### Debt ###
                Mortgage: ${DollarInput.State.get(state[shared.COVER_MORTGAGE_KEY])}
                Student Loans: ${DollarInput.State.get(state[shared.COVER_STUDENT_LOAN_KEY])}
                Other Debt: ${DollarInput.State.get(state[shared.COVER_OTHER_KEY])}
                ### Education ###
                People to fund: ${NumberInput.State.get(state[shared.FUND_NUMBER_KEY])}
                College Years: ${shared.FUND_TYPE_COSTS[state[shared.FUND_TYPE_KEY]].years}
                College Cost: ${Slider.State.getValue(state[shared.FUND_TYPE_COST_KEY])}
                ### Final Expenses ###
                Burial: ${DollarInput.State.get(state[shared.FINAL_EXPENSES_BURIAL])}
                Funeral: ${DollarInput.State.get(state[shared.FINAL_EXPENSES_FUNERAL])}`,
            callBackDate: rfcTime,
            contact: {
                firstName: formatNameInput(NameEntry.State.getName(state[shared.CONTACT_NAME].first)),
                lastName: formatNameInput(NameEntry.State.getName(state[shared.CONTACT_NAME].last)),
                birthdate: DateEntry.State.isValid(state[shared.ABOUT_DOB]) ? DateEntry.State.getDate(state[shared.ABOUT_DOB]) : null,
                gender: Gender.State.isValid(state[shared.ABOUT_GENDER]) ? Gender.State.getGender(state[shared.ABOUT_GENDER]) : 'Unknown',
                phoneNumber: formatPhoneInput(PhoneEntry.State.getPhone(state[shared.CONTACT_PHONE])),
                emailAddress: EmailEntry.State.getEmail(state[shared.CONTACT_EMAIL]).toLowerCase()
            }
        },
        GUID: uuidv4()
    };
};

export default contactValues;
