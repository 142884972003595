/* Height Entry Styling */
.entry {
    display: flex;
    flex-direction: row;
}

.entry > div {
    margin-right: 20px;
}

.feet,
.inches {
    width: 60px;
}

/* general stuff */
.fieldDescription {
    composes: fieldDescription from './common.module.css';
}
