@value smallScreen from './common.module.css';

/* Quote About Form */
.form {
    display: flex;
    max-width: 320px;
    margin: 30px auto 0 auto;
}

@media all and (max-width: smallScreen) {
    .form {
        padding: 0 10px;
    }
}

.formRows {
    display: flex;
    flex-direction: column;
}

.formRow {
    padding-bottom: 20px;
}

.nextButton {
    composes: nextButton from './common.module.css';
}
