import flyd from 'flyd';
import merge from 'mergerino';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/app';


// All of our state instances will be passed through and stored in a stream of
// states. This makes debugging trivial, but also ensures all updates flow
// through in one direction.
const update = flyd.stream();

// We'll use a simple "scanner" to run merge on each update. Our initial state
// is provided by the app, then each update that flows through will be merged
// onto that initial state.
const states = flyd.scan(merge, App.State.Initial(), update);

// _actions_ modify state, so they need an interface through which to request
// those modifications. We'll pass in our update stream they can write updates
// too.
const actions = App.State.Actions(update);

// For each update that passes through the stream, we'd like to render our
// interface with the current state data.
states.map((state) => {
    ReactDOM.render(
        <App.Component states={states} actions={actions} />,
        document.getElementById('app')
    );
    return state;
});

export default actions;
