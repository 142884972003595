@value pdsDarkGray, boldFont, smallScreen, largeScreen, hugeScreen from './common.module.css';

/* Quote Container */
.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 385px;
}

@media all and (max-width: smallScreen) {
    .container {
        min-width: 320px;
        width: 100%;
    }
}

@media all and (min-width: largeScreen) {
    .container {
        width: 768px;
    }

    .sectionTitle {
        font-size: 32px;
    }
}

.hero {
    text-align: center;
}

.hero > img {
    max-width: 225px;
    margin: 8px 0 0 0;
}

.blurb {
    composes: blurb from './info-blurb.module.css';
    margin: 30px 10px 0px 10px;
}

@media all and (min-width: largeScreen) {
    .hero > img {
        max-width: 306px;
    }
}

.sectionTitle {
    composes: sectionTitle from './common.module.css';
}

.loading {
    composes: loading from './loading.module.css';
}

.loadingMessage,
.errorMessage,
.noRatesMessage {
    color: pdsDarkGray;
    font-size: 22px;
    max-width: 400px;
    padding: 70px 15px;
}

.goBackButton {
    display: flex;
    align-items: center;
    justify-content: center;
}
