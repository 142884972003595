import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import convertDate from '../../constants';
import stateChecker from '../../state-checker';
import ErrorMessage from '../error-message';
import styles from '../../css/appointment.module.css';


const validate = state => {
    const today = DateTime.local();
    const date = convertDate(state.value);

    if (state.value === null) {
        if (!state.allowNull) {
            state.isValid = false;
            state.hasError = true;
            state.errorMsg = `${state.title} can not be empty`;
            return state;
        }

        state.isValid = true;
        state.hasError = false;
        state.errorMsg = null;
        return state;
    }

    if (date.invalid) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = `${state.title} is invalid`;
        return state;
    }


    if (date.weekday === 6 || date.weekday === 7) {
        state.isValid = false;
        state.hasError = true;
        state.errorMsg = 'Our Insurance Professionals are only available Monday - Friday.';
        return state;
    }

    if ((today.hasSame(date, 'day')) || date > today) {
        state.isValid = true;
        state.hasError = false;
        state.errorMsg = null;
        return state;
    }

    state.isValid = false;
    state.hasError = true;
    state.errorMsg = `${state.title} can not be in the past`;

    return state;
};

const set = (state, input) => {
    if (input === '') {
        state.value = null;
        return validate(state);
    }

    state.value = input;
    return state;
};


const AppointmentInputState = {
    Initial: (id, userParams) => {
        const params = {
            allowNull: false,
            title: null,
            ...userParams,
            value: null // If the default value is invalid don't use it.
        };

        let state = {
            // User params
            ...params,
            // Internal state
            isValid: params.allowNull && (
                !userParams
                || (userParams.value === null || userParams.value === undefined)
            ),
            hasError: false,
            errorMsg: null
        };

        if (userParams
            && (userParams.value !== null || userParams.value !== undefined)
        ) {
            state = set(state, userParams.value);
        }

        if (id !== null && id !== undefined) {
            return { [id]: state };
        }

        return state;
    },
    set,
    getAppointment: ({ value }) => (
        value
    ),
    validate,
    isValid: ({ isValid }) => isValid,
    getErrorState: ({ hasError, errorMsg }) => ({ hasError, errorMsg }),
    isEmpty: ({ value }) => (value === null || value.length === 0),
    Actions: update => ({
        validateAppointment: id => update({ [id]: state => validate(state) }),
        setAppointment: (id, input) => update({ [id]: state => set(state, input) })
    })
};


const AppointmentInputComponent = (
    { id, state, actions, onChange, onFocusChange }
) => (
    <>
        <input className={styles.entry}
            type="date"
            inputMode="numeric"
            id={`appointment-${id}`}
            placeholder="MM/DD/YYYY"
            aria-label="Date"
            value={state[id].value || ''}
            onChange={evt => {
                actions.setAppointment(id, evt.target.value);
                if (onChange) { onChange(evt); }
            }}
            onFocus={evt => {
                if (onFocusChange) { onFocusChange(true, evt); }
            }}
            onBlur={evt => {
                actions.setAppointment(id, evt.target.value);
                actions.validateAppointment(id);
                if (onFocusChange) { onFocusChange(false, evt); }
            }}
        />
        <ErrorMessage hasError={state[id].hasError} errorMsg={state[id].errorMsg} className={styles.error} />
    </>
);

AppointmentInputComponent.defaultProps = { onChange: null, onFocusChange: null };

const actionPropTypes = PropTypes.shape(
    {
        setAppointment: PropTypes.func.isRequired,
        validateAppointment: PropTypes.func.isRequired
    }
);

const statePropTypes = PropTypes.shape(
    {
        value: PropTypes.string,
        isValid: PropTypes.bool.isRequired,
        hasError: PropTypes.bool.isRequired,
        errorMsg: PropTypes.string
    }
);

AppointmentInputComponent.propTypes = {
    id: PropTypes.string.isRequired,
    actions: actionPropTypes.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        statePropTypes.isRequired
    ),
    onChange: PropTypes.func,
    onFocusChange: PropTypes.func
};


export default {
    State: AppointmentInputState,
    Component: AppointmentInputComponent,
    statePropTypes,
    actionPropTypes
};
