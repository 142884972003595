"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.computeReplaceIncome = exports.computeFundEducation = exports.computeFinalExpenses = exports.computeCoverDebt = void 0;
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* Helpers to compute calculator total values */
var computeReplaceIncome = exports.computeReplaceIncome = function computeReplaceIncome(baseIncome, growthRate, years) {
  var annualGrowthRate = 1 + growthRate / 100;
  var income = 0;
  for (var year = 0; year < years; year += 1) {
    income += Math.round(baseIncome * Math.pow(annualGrowthRate, year));
  }
  return income;
};
var computeCoverDebt = exports.computeCoverDebt = function computeCoverDebt(mortgage, studentLoans, other) {
  return mortgage + studentLoans + other;
};
var computeFundEducation = exports.computeFundEducation = function computeFundEducation(attendees, years, annualCost) {
  return attendees * years * annualCost;
};
var computeFinalExpenses = exports.computeFinalExpenses = function computeFinalExpenses(burial, funeral) {
  return burial + funeral;
};
var _default = exports["default"] = {
  computeReplaceIncome: computeReplaceIncome,
  computeCoverDebt: computeCoverDebt,
  computeFundEducation: computeFundEducation,
  computeFinalExpenses: computeFinalExpenses
};