@value pdsDarkGray, boldFont from './common.module.css';

.loading {
    composes: loading from './loading.module.css';
}

.loadingMessage {
    color: pdsDarkGray;
    font-family: boldFont;
}
