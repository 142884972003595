@value pdsDarkGray, pdsMediumLightGray, pdsWarning, boldFont from './common.module.css';

/* Info Blurbs */
.blurb {
    display: flex;
    flex-direction: column;
    border: 1px solid pdsMediumLightGray;
    border-left: 3px solid pdsWarning;
    max-width: 455px;
    margin: 50px 10px 0px 10px;
    padding: 20px;
}

.blurb .title {
    font-size: 20px;
    font-family: boldFont;
    color: pdsDarkGray;
}

.blurb .body {
    font-size: 16px;
    color: pdsDarkGray;
}

