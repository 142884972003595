import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../css/tooltip.module.css';


const TooltipComponent = ({ tip, position }) => (
    <div className={styles.tooltip}
        data-tooltip={tip}
        data-tooltip-position={position}
    >
        ?
    </div>
);

TooltipComponent.defaultProps = { position: '' };

TooltipComponent.propTypes = {
    tip: PropTypes.string.isRequired,
    position: PropTypes.string
};


export default { Component: TooltipComponent };
