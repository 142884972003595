import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { webAnalytics as wa } from '@principal/web-analytics';
import DateEntry from '../date-entry';
import Gender from '../gender';
import Height from '../height';
import Weight from '../weight';
import Tobacco from '../tobacco';
import StateEntry from '../multiple-input';
import Label from '../label';

import styles from '../../css/quote-about-form.module.css';

import {
    ABOUT_DOB,
    ABOUT_GENDER,
    ABOUT_STATE,
    ABOUT_STATE_OPTIONS,
    ABOUT_HEIGHT,
    ABOUT_WEIGHT,
    ABOUT_TOBACCO
} from '../../constants';


const isValid = (state) => (
    DateEntry.State.isValid(state[ABOUT_DOB])
    && Gender.State.isValid(state[ABOUT_GENDER])
    && StateEntry.State.isValid(state[ABOUT_STATE])
    && Height.State.isValid(state[ABOUT_HEIGHT])
    && Weight.State.isValid(state[ABOUT_WEIGHT])
    && Tobacco.State.isValid(state[ABOUT_TOBACCO])
);

const validateForm = (state, actions) => {
    // Trigger validation runs.
    actions.validateDate(ABOUT_DOB);
    actions.validateGender(ABOUT_GENDER);
    actions.validateMultiple(ABOUT_STATE);
    actions.validateHeight(ABOUT_HEIGHT);
    actions.validateWeight(ABOUT_WEIGHT);
    actions.validateTobacco(ABOUT_TOBACCO);

    return isValid(state);
};


const QuoteAboutFormState = ({
    Initial: () => ({
        [ABOUT_DOB]: DateEntry.State.Initial(),
        [ABOUT_GENDER]: Gender.State.Initial(),
        ...StateEntry.State.Initial(ABOUT_STATE, {
            options: ABOUT_STATE_OPTIONS,
            value: 'IA',
            title: 'State',
            allowNull: false
        }),
        ...Height.State.Initial(ABOUT_HEIGHT),
        ...Weight.State.Initial(ABOUT_WEIGHT),
        [ABOUT_TOBACCO]: Tobacco.State.Initial()
    }),
    isValid,
    validateForm,
    Actions: (update) => (
        {
            ...DateEntry.State.Actions(update),
            ...Gender.State.Actions(update),
            ...StateEntry.State.Actions(update),
            ...Height.State.Actions(update),
            ...Weight.State.Actions(update),
            ...Tobacco.State.Actions(update)
        }
    )
});


const QuoteAboutFormComponent = ({ state, actions }) => {
    const history = useHistory();

    return (
        <form className={styles.form}>
            <div className={styles.formRows}>
                <div className={styles.formRow}>
                    <DateEntry.Component state={state}
                        id={ABOUT_DOB}
                        actions={actions}
                    />
                </div>
                <div className={styles.formRow}>
                    <Gender.Component state={state}
                        id={ABOUT_GENDER}
                        actions={actions}
                    />
                </div>
                <div className={styles.formRow}>
                    <Label.Component htmlFor={`${ABOUT_STATE}`}
                        label="State"
                    />
                    <StateEntry.Component state={state}
                        id={ABOUT_STATE}
                        actions={actions}
                    />
                </div>
                <div className={styles.formRow}>
                    <Height.Component state={state}
                        id={ABOUT_HEIGHT}
                        actions={actions}
                    />
                </div>
                <div className={styles.formRow}>
                    <Weight.Component state={state}
                        id={ABOUT_WEIGHT}
                        actions={actions}
                    />
                </div>
                <div className={styles.formRow}>
                    <Tobacco.Component state={state}
                        id={ABOUT_TOBACCO}
                        actions={actions}
                    />
                </div>
                <div className={styles.nextButton}>
                    <button className="pds-button pds-button-primary"
                        type="submit"
                        disabled={!isValid(state)}
                        onClick={(evt) => {
                            evt.preventDefault();
                            actions.requestRates(state, actions, history);
                            window.scrollTo(0, 0);
                            wa.trackEvent('formSubmit', {
                                type: 'contact-prospect',
                                lead: 'yes',
                                name: 'Get Quote'
                            });
                        }}
                    >
                        Continue
                    </button>
                </div>
            </div>
        </form>
    );
};

QuoteAboutFormComponent.propTypes = {
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
    state: PropTypes.shape({
        [ABOUT_DOB]: PropTypes.object.isRequired,
        [ABOUT_GENDER]: PropTypes.object.isRequired,
        [ABOUT_STATE]: PropTypes.object.isRequired,
        [ABOUT_HEIGHT]: PropTypes.object.isRequired,
        [ABOUT_WEIGHT]: PropTypes.object.isRequired,
        [ABOUT_TOBACCO]: PropTypes.object.isRequired
    }).isRequired
};


export default {
    State: QuoteAboutFormState,
    Component: QuoteAboutFormComponent
};
