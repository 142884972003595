@value principalBlue, regularFont, pdsDarkGray, pdsMediumLightGray from './common.module.css';
@value tinyScreen, smallScreen, largeScreen, hugeScreen from './common.module.css';

/* Application wrapper styles. */
/* App Container */
:global #app {
    position:relative;
    margin-top: 60px;
    display: flex;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: center;
}

.appWrapper {
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 15px 15px 0px 15px;
    min-width: 290px;
    width: 100%;
}

@media all and (min-width: largeScreen) {
    .appWrapper {
        max-width: 1000px;
    }
}

.appContainer {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

/* App Flow Selector */
.selector {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
}

.sectionTitle {
    composes: sectionTitle from './common.module.css';
}

.options {
    display: flex;
    flex-direction: column;
}

.option {
    margin: 10px 5px 10px 5px;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    border-color: pdsMediumLightGray;
    border-width: 1px;
    border-style: solid;
    flex: 1;
    height: 150px;
    cursor: pointer;
    user-select: none;
}

.option:hover {
    border-color: principalBlue;
}

.icon {
    width: 90px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon img {
    max-width: 60px;
    max-height: 60px;
}

.body {
    flex: 1;
    margin: 25px 10px 10px 10px;

    display: flex;
    flex-direction: column;

    color: pdsDarkGray;
}

.options a,
.options a:hover {
    text-decoration: none;
    color: pdsDarkGray;
    font-family: regularFont;
}

.title {
    font-size: 20px;
    margin: 0;
}

.description {
    font-size: 14px;
    font-weight: normal;
}

@media all and (min-width: largeScreen) {
    .selector {
        width: 650px;
    }

    .options {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        margin-top: 20px;
    }

    .option {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 250px;
        height: 250px;
        padding: 10px;
    }

    .icon {
        height: 70px;
    }

    .body {
        padding: 0;
        margin: 10px 10px 5px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .title {
        font-size: 22px;
    }

    .description {
        font-size: 16px;
    }
}
