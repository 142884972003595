@value pdsDarkGray, pdsMediumLightGray, regularFont, tinyScreen, smallScreen, largeScreen, hugeScreen from './common.module.css';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media all and (min-width: largeScreen) {
    .container {
        width: 750px;
    }
}

@media all and (min-width: hugeScreen) {
    .container {
        width: 1000px;
    }
}

.sectionTitle {
    composes: sectionTitle from './common.module.css';
    font-size: 32px;
}

.getHelpText {
    max-width: 560px;
    color: pdsDarkGray;
    font-size: 20px;
    line-height: 25px;
    font-family: regularFont;
    text-align: center;
    margin-bottom: 40px;
}

.getHelpText > div {
    padding: 10px 0;
}

@media all and (min-width: largeScreen) {
    .getHelpText {
        width: 450px;
    }
}

@media all and (min-width: hugeScreen) {
    .getHelpText {
        width: 500px;
    }
}
