import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import stateChecker from '../../state-checker';

import styles from '../../css/modal.module.css';

const ModalState = ({
    Initial: id => {
        const state = {
            showing: false,
            body: null,
            header: null
        };
        if (id) {
            return { [id]: state };
        }
        return state;
    },
    Actions: update => ({
        showModal: id => update({ [id]: { showing: true } }),
        hideModal: id => update({ [id]: { showing: false } }),
        // Note: body and header are both React components, i.e., functions.
        // We have to wrap body and header in a function/thunk due to how mergerino handles functions in the update flow.
        // If we just passed it the React component, mergerino will try to set the header/body state
        // as the return value of the React component.
        // See mergerino docs: https://github.com/fuzetsu/mergerino#usage-guide
        // Notably the part where it says "If you want to replace a property based on its current value, use a function."
        showModalContent: (id, header, body) => update({
            [id]: {
                showing: true,
                header: () => header,
                body: () => body
            }
        })
    })
});


const Modal = ({ id, state, actions }) => {
    if (!state[id].showing) {
        return null;
    }

    const closeModal = () => { actions.hideModal(id); };

    useEffect(() => {
        const handler = evt => ((evt.keyCode === 27) ? closeModal() : null);
        window.addEventListener('keydown', handler);
        return () => window.removeEventListener('keydown', handler);
    }, []);

    return (
        <div className={styles.wrapper}>
            <aside id="modal-content" className={styles.modal}>
                <div className={styles.header}>
                    {state[id].header(state, actions)}
                    <button type="button"
                        className={styles.close}
                        aria-label="close"
                        onClick={evt => {
                            closeModal();
                            evt.preventDefault();
                        }
                        }
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className={styles.content}>
                    {state[id].body(state, actions)}
                </div>
            </aside>
        </div>
    );
};

Modal.defaultProps = {
    header: () => null, // eslint-disable-line react/default-props-match-prop-types
    content: () => null // eslint-disable-line react/default-props-match-prop-types
};

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    state: stateChecker( // eslint-disable-line react/require-default-props
        PropTypes.shape({
            showing: PropTypes.bool.isRequired,
            header: PropTypes.func,
            content: PropTypes.func
        })
    ),
    actions: PropTypes.objectOf(PropTypes.func).isRequired
};


export default {
    State: ModalState,
    Component: Modal
};
